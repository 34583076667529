import React from 'react';
import {Form, Col, Row, Button} from 'react-bootstrap'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import CreatableSelect from 'react-select/creatable';
import 'holderjs';
import './PeoplePage.css';
import Loader from 'react-loader-spinner';
import autoBind from 'react-autobind';

class AddCompany extends React.Component {
  constructor(props) {
    super();
    autoBind(this);
    this.state = {
      company: {
          name: props.selected.name || '',
          website: props.selected.website || '',
          hq_location: props.selected.hq_location || '',
          sectors: props.selected.sectors || [],
          is_public: props.selected.is_public || false,
      },
      loading: false,
      selectedCompany: props.selected,
      submitCallback: props.submitCallback,
      companySectors: [
        {'label': 'Coal (NEC)', 'value': 'Coal (NEC)'},
        {'label': 'Coal Mining Support', 'value': 'Coal Mining Support'},
        {'label': 'Coal Wholesale', 'value': 'Coal Wholesale'},
        {'label': 'Integrated Oil & Gas', 'value': 'Integrated Oil & Gas'},
        {'label': 'Oil & Gas Exploration and Production (NEC)', 'value': 'Oil & Gas Exploration and Production (NEC)'},
        {'label': 'Oil Exploration & Production - Onshore', 'value': 'Oil Exploration & Production - Onshore'},
        {'label': 'Oil Exploration & Production - Offshore', 'value': 'Oil Exploration & Production - Offshore'},
        {'label': 'Natural Gas Exploration & Production - Onshore', 'value': 'Natural Gas Exploration & Production - Onshore'},
        {'label': 'Natural Gas Exploration & Production - Offshore', 'value': 'Natural Gas Exploration & Production - Offshore'},
        {'label': 'Unconventional Oil & Gas Production', 'value': 'Unconventional Oil & Gas Production'},
        {'label': 'Oil & Gas Refining and Marketing (NEC)', 'value': 'Oil & Gas Refining and Marketing (NEC)'},
        {'label': 'Petroleum Refining', 'value': 'Petroleum Refining'},
        {'label': 'Gasoline Stations', 'value': 'Gasoline Stations'},
        {'label': 'Petroleum Product Wholesale', 'value': 'Petroleum Product Wholesale'},
        {'label': 'Oil & Gas Drilling (NEC)', 'value': 'Oil & Gas Drilling (NEC)'},
        {'label': 'Oil Drilling - Onshore', 'value': 'Oil Drilling - Onshore'},
        {'label': 'Gas Drilling - Onshore', 'value': 'Gas Drilling - Onshore'},
        {'label': 'Oil Drilling - Offshore', 'value': 'Oil Drilling - Offshore'},
        {'label': 'Gas Drilling - Offshore', 'value': 'Gas Drilling - Offshore'},
        {'label': 'Unconventional Oil & Gas Drilling', 'value': 'Unconventional Oil & Gas Drilling'},
        {'label': 'Oil Related Services and Equipment (NEC)', 'value': 'Oil Related Services and Equipment (NEC)'},
        {'label': 'Oil Related Services', 'value': 'Oil Related Services'},
        {'label': 'Oil Related Equipment', 'value': 'Oil Related Equipment'},
        {'label': 'Oil Related - Surveying & Mapping Services', 'value': 'Oil Related - Surveying & Mapping Services'},
        {'label': 'Oil & Gas Transportation Services (NEC)', 'value': 'Oil & Gas Transportation Services (NEC)'},
        {'label': 'LNG Transportation & Storage', 'value': 'LNG Transportation & Storage'},
        {'label': 'Natural Gas Pipeline Transportation', 'value': 'Natural Gas Pipeline Transportation'},
        {'label': 'Oil Pipeline Transportation', 'value': 'Oil Pipeline Transportation'},
        {'label': 'Sea-Borne Tankers', 'value': 'Sea-Borne Tankers'},
        {'label': 'Oil & Gas Storage', 'value': 'Oil & Gas Storage'},
        {'label': 'Renewable Energy Equipment & Services (NEC)', 'value': 'Renewable Energy Equipment & Services (NEC)'},
        {'label': 'Wind Systems & Equipment', 'value': 'Wind Systems & Equipment'},
        {'label': 'Stationary Fuel Cells', 'value': 'Stationary Fuel Cells'},
        {'label': 'Photovoltaic Solar Systems & Equipment', 'value': 'Photovoltaic Solar Systems & Equipment'},
        {'label': 'Thermal Solar Systems & Equipment', 'value': 'Thermal Solar Systems & Equipment'},
        {'label': 'Biomass Power Energy Equipment', 'value': 'Biomass Power Energy Equipment'},
        {'label': 'Waste to Energy Systems & Equipment', 'value': 'Waste to Energy Systems & Equipment'},
        {'label': 'Hydropower Equipment', 'value': 'Hydropower Equipment'},
        {'label': 'Wave Power Energy Equipment', 'value': 'Wave Power Energy Equipment'},
        {'label': 'Renewable Energy Services', 'value': 'Renewable Energy Services'},
        {'label': 'Geothermal Equipment', 'value': 'Geothermal Equipment'},
        {'label': 'Renewable Fuels (NEC)', 'value': 'Renewable Fuels (NEC)'},
        {'label': 'Biodiesel', 'value': 'Biodiesel'},
        {'label': 'Ethanol Fuels', 'value': 'Ethanol Fuels'},
        {'label': 'Pyrolytic & Synthetic Fuels', 'value': 'Pyrolytic & Synthetic Fuels'},
        {'label': 'Biomass & Biogas Fuels', 'value': 'Biomass & Biogas Fuels'},
        {'label': 'Hydrogen Fuel', 'value': 'Hydrogen Fuel'},
        {'label': 'Uranium (NEC)', 'value': 'Uranium (NEC)'},
        {'label': 'Uranium Mining', 'value': 'Uranium Mining'},
        {'label': 'Uranium Processing', 'value': 'Uranium Processing'},
        {'label': 'Commodity Chemicals (NEC)', 'value': 'Commodity Chemicals (NEC)'},
        {'label': 'Plastics', 'value': 'Plastics'},
        {'label': 'Paints & Coatings', 'value': 'Paints & Coatings'},
        {'label': 'Tanning & Softening Agents', 'value': 'Tanning & Softening Agents'},
        {'label': 'Explosives', 'value': 'Explosives'},
        {'label': 'Industrial Gases', 'value': 'Industrial Gases'},
        {'label': 'Commodity Chemicals Wholesale', 'value': 'Commodity Chemicals Wholesale'},
        {'label': 'Glass', 'value': 'Glass'},
        {'label': 'Agricultural Chemicals (NEC)', 'value': 'Agricultural Chemicals (NEC)'},
        {'label': 'Fertilizers', 'value': 'Fertilizers'},
        {'label': 'Pesticides', 'value': 'Pesticides'},
        {'label': 'Organic Fertilizers', 'value': 'Organic Fertilizers'},
        {'label': 'Agricultural Chemicals Wholesale', 'value': 'Agricultural Chemicals Wholesale'},
        {'label': 'Specialty Chemicals (NEC)', 'value': 'Specialty Chemicals (NEC)'},
        {'label': 'Coloring Agents', 'value': 'Coloring Agents'},
        {'label': 'Synthetic Fibers', 'value': 'Synthetic Fibers'},
        {'label': 'Advanced Polymers', 'value': 'Advanced Polymers'},
        {'label': 'Industrial Biotechnology Chemicals', 'value': 'Industrial Biotechnology Chemicals'},
        {'label': 'Specialty Chemicals Wholesale', 'value': 'Specialty Chemicals Wholesale'},
        {'label': 'Adhesive & Epoxy', 'value': 'Adhesive & Epoxy'},
        {'label': 'Diversified Chemicals', 'value': 'Diversified Chemicals'},
        {'label': 'Non-Gold Precious Metals & Minerals (NEC)', 'value': 'Non-Gold Precious Metals & Minerals (NEC)'},
        {'label': 'Silver Mining', 'value': 'Silver Mining'},
        {'label': 'Platinum Mining', 'value': 'Platinum Mining'},
        {'label': 'Diamond Mining', 'value': 'Diamond Mining'},
        {'label': 'Semiprecious Gem Stones', 'value': 'Semiprecious Gem Stones'},
        {'label': 'Pearl Cultivation', 'value': 'Pearl Cultivation'},
        {'label': 'Rare Earth Minerals', 'value': 'Rare Earth Minerals'},
        {'label': 'Iron & Steel (NEC)', 'value': 'Iron & Steel (NEC)'},
        {'label': 'Iron Ore Mining', 'value': 'Iron Ore Mining'},
        {'label': 'Coke Coal Mining', 'value': 'Coke Coal Mining'},
        {'label': 'Iron, Steel Mills & Foundries', 'value': 'Iron, Steel Mills & Foundries'},
        {'label': 'Metal Service Centers', 'value': 'Metal Service Centers'},
        {'label': 'Metallic Rolling & Drawing Products', 'value': 'Metallic Rolling & Drawing Products'},
        {'label': 'Metal Merchant Wholesale', 'value': 'Metal Merchant Wholesale'},
        {'label': 'Aluminum (NEC)', 'value': 'Aluminum (NEC)'},
        {'label': 'Primary Aluminum Production', 'value': 'Primary Aluminum Production'},
        {'label': 'Secondary Smelting & Alloying of Aluminum', 'value': 'Secondary Smelting & Alloying of Aluminum'},
        {'label': 'Aluminum Rolling', 'value': 'Aluminum Rolling'},
        {'label': 'Aluminum Refining', 'value': 'Aluminum Refining'},
        {'label': 'Aluminum Wholesale', 'value': 'Aluminum Wholesale'},
        {'label': 'Bauxite Mining', 'value': 'Bauxite Mining'},
        {'label': 'Specialty Mining & Metals (NEC)', 'value': 'Specialty Mining & Metals (NEC)'},
        {'label': 'Lead Ore Mining', 'value': 'Lead Ore Mining'},
        {'label': 'Copper Ore Mining', 'value': 'Copper Ore Mining'},
        {'label': 'Nickel Ore Mining', 'value': 'Nickel Ore Mining'},
        {'label': 'Zinc Ore Mining', 'value': 'Zinc Ore Mining'},
        {'label': 'Nonferrous Metal Mining', 'value': 'Nonferrous Metal Mining'},
        {'label': 'Nonferrous Metal Processing', 'value': 'Nonferrous Metal Processing'},
        {'label': 'Specialty Mining & Metals Wholesale', 'value': 'Specialty Mining & Metals Wholesale'},
        {'label': 'Gold (NEC)', 'value': 'Gold (NEC)'},
        {'label': 'Gold Mining', 'value': 'Gold Mining'},
        {'label': 'Gold Refining', 'value': 'Gold Refining'},
        {'label': 'Mining Support Services & Equipment (NEC)', 'value': 'Mining Support Services & Equipment (NEC)'},
        {'label': 'Geophysical Surveying & Mapping Services', 'value': 'Geophysical Surveying & Mapping Services'},
        {'label': 'Mining Support Services', 'value': 'Mining Support Services'},
        {'label': 'Mining Machinery & Equipment Manufacturing', 'value': 'Mining Machinery & Equipment Manufacturing'},
        {'label': 'Diversified Mining', 'value': 'Diversified Mining'},
        {'label': 'Construction Materials (NEC)', 'value': 'Construction Materials (NEC)'},
        {'label': 'Construction Material Processing', 'value': 'Construction Material Processing'},
        {'label': 'Cement & Concrete Manufacturing', 'value': 'Cement & Concrete Manufacturing'},
        {'label': 'Tile & Paving Material Manufacturing', 'value': 'Tile & Paving Material Manufacturing'},
        {'label': 'Rock Mining', 'value': 'Rock Mining'},
        {'label': 'Construction Material Wholesale', 'value': 'Construction Material Wholesale'},
        {'label': 'Forest & Wood Products (NEC)', 'value': 'Forest & Wood Products (NEC)'},
        {'label': 'Timber Tract Operations', 'value': 'Timber Tract Operations'},
        {'label': 'Forest Nurseries & Gathering of Forest Products', 'value': 'Forest Nurseries & Gathering of Forest Products'},
        {'label': 'Logging & Sawmills', 'value': 'Logging & Sawmills'},
        {'label': 'Forest Support & Services', 'value': 'Forest Support & Services'},
        {'label': 'Wood Products', 'value': 'Wood Products'},
        {'label': 'Wood Product Wholesale', 'value': 'Wood Product Wholesale'},
        {'label': 'Paper Products (NEC)', 'value': 'Paper Products (NEC)'},
        {'label': 'Paper Mills & Products', 'value': 'Paper Mills & Products'},
        {'label': 'Newsprint Mills', 'value': 'Newsprint Mills'},
        {'label': 'Pulp Mills', 'value': 'Pulp Mills'},
        {'label': 'Paper Product Wholesale', 'value': 'Paper Product Wholesale'},
        {'label': 'Non-Paper Containers & Packaging (NEC)', 'value': 'Non-Paper Containers & Packaging (NEC)'},
        {'label': 'Textile Containers & Packaging', 'value': 'Textile Containers & Packaging'},
        {'label': 'Glass Containers & Packaging', 'value': 'Glass Containers & Packaging'},
        {'label': 'Metal Containers & Packaging', 'value': 'Metal Containers & Packaging'},
        {'label': 'Plastic Containers & Packaging', 'value': 'Plastic Containers & Packaging'},
        {'label': 'Wood Container & Packaging', 'value': 'Wood Container & Packaging'},
        {'label': 'Container & Packaging Material Wholesale', 'value': 'Container & Packaging Material Wholesale'},
        {'label': 'Paper Packaging (NEC)', 'value': 'Paper Packaging (NEC)'},
        {'label': 'Paper Packaging Wholesale', 'value': 'Paper Packaging Wholesale'},
        {'label': 'Aerospace & Defense (NEC)', 'value': 'Aerospace & Defense (NEC)'},
        {'label': 'Arms & Ammunitions Manufacturing', 'value': 'Arms & Ammunitions Manufacturing'},
        {'label': 'Commercial Aircraft Manufacturing', 'value': 'Commercial Aircraft Manufacturing'},
        {'label': 'Military Aircraft Manufacturing', 'value': 'Military Aircraft Manufacturing'},
        {'label': 'Aircraft Parts Manufacturing', 'value': 'Aircraft Parts Manufacturing'},
        {'label': 'Military Vehicles Manufacturing', 'value': 'Military Vehicles Manufacturing'},
        {'label': 'Satellite Design & Manufacture', 'value': 'Satellite Design & Manufacture'},
        {'label': 'Spacecraft Manufacturing', 'value': 'Spacecraft Manufacturing'},
        {'label': 'Military Clothing & Accessories', 'value': 'Military Clothing & Accessories'},
        {'label': 'Aircraft Equipment Wholesale', 'value': 'Aircraft Equipment Wholesale'},
        {'label': 'Aerospace & Defense Electronics', 'value': 'Aerospace & Defense Electronics'},
        {'label': 'Drone Manufacturing', 'value': 'Drone Manufacturing'},
        {'label': 'Industrial Machinery & Equipment (NEC)', 'value': 'Industrial Machinery & Equipment (NEC)'},
        {'label': 'Industrial Components', 'value': 'Industrial Components'},
        {'label': 'Industrial Machinery', 'value': 'Industrial Machinery'},
        {'label': 'Ball & Roller Bearings', 'value': 'Ball & Roller Bearings'},
        {'label': 'Testing & Measuring Equipment', 'value': 'Testing & Measuring Equipment'},
        {'label': 'Pump & Pumping Equipment', 'value': 'Pump & Pumping Equipment'},
        {'label': 'Air & Gas Compressors', 'value': 'Air & Gas Compressors'},
        {'label': 'Welding & Soldering Equipment', 'value': 'Welding & Soldering Equipment'},
        {'label': 'Industrial Process Furnace & Ovens', 'value': 'Industrial Process Furnace & Ovens'},
        {'label': 'Fluid Power Cylinder & Actuators', 'value': 'Fluid Power Cylinder & Actuators'},
        {'label': 'Automatic Vending Machines', 'value': 'Automatic Vending Machines'},
        {'label': 'Industrial Moulds', 'value': 'Industrial Moulds'},
        {'label': 'Machine Tools', 'value': 'Machine Tools'},
        {'label': 'Industrial Valve Manufacturing', 'value': 'Industrial Valve Manufacturing'},
        {'label': 'Industrial Machinery & Equipment Wholesale', 'value': 'Industrial Machinery & Equipment Wholesale'},
        {'label': 'Commercial Equipment', 'value': 'Commercial Equipment'},
        {'label': 'Heavy Machinery & Vehicles (NEC)', 'value': 'Heavy Machinery & Vehicles (NEC)'},
        {'label': 'Construction Machinery', 'value': 'Construction Machinery'},
        {'label': 'Heavy Trucks', 'value': 'Heavy Trucks'},
        {'label': 'Heavy Buses & Coaches', 'value': 'Heavy Buses & Coaches'},
        {'label': 'Locomotive Engines & Rolling Stock', 'value': 'Locomotive Engines & Rolling Stock'},
        {'label': 'Agricultural Machinery', 'value': 'Agricultural Machinery'},
        {'label': 'Commercial Landscaping Equipment', 'value': 'Commercial Landscaping Equipment'},
        {'label': 'Heavy Machinery & Vehicles Wholesale', 'value': 'Heavy Machinery & Vehicles Wholesale'},
        {'label': 'Electrical Components & Equipment (NEC)', 'value': 'Electrical Components & Equipment (NEC)'},
        {'label': 'Batteries & Uninterruptable Power Supplies', 'value': 'Batteries & Uninterruptable Power Supplies'},
        {'label': 'Wires & Cables', 'value': 'Wires & Cables'},
        {'label': 'Electrical Components', 'value': 'Electrical Components'},
        {'label': 'Lighting Equipment', 'value': 'Lighting Equipment'},
        {'label': 'Heating, Ventilation & Air Conditioning Systems', 'value': 'Heating, Ventilation & Air Conditioning Systems'},
        {'label': 'Electrical Insulators', 'value': 'Electrical Insulators'},
        {'label': 'Switchgear', 'value': 'Switchgear'},
        {'label': 'Portable Motors & Generators', 'value': 'Portable Motors & Generators'},
        {'label': 'Electrical Measuring & Testing Instruments', 'value': 'Electrical Measuring & Testing Instruments'},
        {'label': 'Electric Equipment Wholesale', 'value': 'Electric Equipment Wholesale'},
        {'label': 'Heavy Electrical Equipment (NEC)', 'value': 'Heavy Electrical Equipment (NEC)'},
        {'label': 'Electrical Transmission & Grid Equipment', 'value': 'Electrical Transmission & Grid Equipment'},
        {'label': 'Elevator & Conveying Equipment', 'value': 'Elevator & Conveying Equipment'},
        {'label': 'Turbine Manufacturing', 'value': 'Turbine Manufacturing'},
        {'label': 'Heavy Motors & Generators', 'value': 'Heavy Motors & Generators'},
        {'label': 'Industrial Electrical Switchgear', 'value': 'Industrial Electrical Switchgear'},
        {'label': 'Nuclear Generators & Components', 'value': 'Nuclear Generators & Components'},
        {'label': 'Shipbuilding (NEC)', 'value': 'Shipbuilding (NEC)'},
        {'label': 'Ship Parts Manufacturers', 'value': 'Ship Parts Manufacturers'},
        {'label': 'Ship Repairing & Maintenance', 'value': 'Ship Repairing & Maintenance'},
        {'label': 'Construction & Engineering (NEC)', 'value': 'Construction & Engineering (NEC)'},
        {'label': 'Commercial Buildings', 'value': 'Commercial Buildings'},
        {'label': 'Highway & Bridge Construction', 'value': 'Highway & Bridge Construction'},
        {'label': 'Railway Construction', 'value': 'Railway Construction'},
        {'label': 'Power & Communications Network Construction', 'value': 'Power & Communications Network Construction'},
        {'label': 'Civil Engineers & Architects', 'value': 'Civil Engineers & Architects'},
        {'label': 'Building Contractors', 'value': 'Building Contractors'},
        {'label': 'Industrial Plant Construction', 'value': 'Industrial Plant Construction'},
        {'label': 'Water & Sewage Construction', 'value': 'Water & Sewage Construction'},
        {'label': 'Land Division & Subdivision', 'value': 'Land Division & Subdivision'},
        {'label': 'Gas Infrastructure Construction', 'value': 'Gas Infrastructure Construction'},
        {'label': 'Electric Power Plant Construction', 'value': 'Electric Power Plant Construction'},
        {'label': 'Nuclear Power Plant Construction', 'value': 'Nuclear Power Plant Construction'},
        {'label': 'Telecommunication Construction', 'value': 'Telecommunication Construction'},
        {'label': 'Diversified Industrial Goods Wholesale', 'value': 'Diversified Industrial Goods Wholesale'},
        {'label': 'Environmental Services & Equipment (NEC)', 'value': 'Environmental Services & Equipment (NEC)'},
        {'label': 'Purification & Treatment Equipment', 'value': 'Purification & Treatment Equipment'},
        {'label': 'Waste Management, Disposal & Recycling Services', 'value': 'Waste Management, Disposal & Recycling Services'},
        {'label': 'Environmental Consultancy Services', 'value': 'Environmental Consultancy Services'},
        {'label': 'Environmental R&D Services & Biotechnology', 'value': 'Environmental R&D Services & Biotechnology'},
        {'label': 'Carbon Capture & Storage', 'value': 'Carbon Capture & Storage'},
        {'label': 'Commercial Printing Services (NEC)', 'value': 'Commercial Printing Services (NEC)'},
        {'label': 'Specialized Printing Services', 'value': 'Specialized Printing Services'},
        {'label': 'Newspaper & Magazine Printing Services', 'value': 'Newspaper & Magazine Printing Services'},
        {'label': 'Book Printing Services', 'value': 'Book Printing Services'},
        {'label': 'Employment Services (NEC)', 'value': 'Employment Services (NEC)'},
        {'label': 'Human Resources Consulting Services', 'value': 'Human Resources Consulting Services'},
        {'label': 'Outsourcing & Staffing Services', 'value': 'Outsourcing & Staffing Services'},
        {'label': 'Executive Search Services', 'value': 'Executive Search Services'},
        {'label': 'Online Job Portals', 'value': 'Online Job Portals'},
        {'label': 'Business Support Services (NEC)', 'value': 'Business Support Services (NEC)'},
        {'label': 'Corporate Accounting Services', 'value': 'Corporate Accounting Services'},
        {'label': 'Legal Services', 'value': 'Legal Services'},
        {'label': 'Management Consulting Services', 'value': 'Management Consulting Services'},
        {'label': 'Security Services', 'value': 'Security Services'},
        {'label': 'Cleaning Services', 'value': 'Cleaning Services'},
        {'label': 'Data Processing Services', 'value': 'Data Processing Services'},
        {'label': 'Industrial Equipment Rental', 'value': 'Industrial Equipment Rental'},
        {'label': 'Office Equipment & Supplies Rental', 'value': 'Office Equipment & Supplies Rental'},
        {'label': 'Pest Control Services', 'value': 'Pest Control Services'},
        {'label': 'Maintenance & Repair Services', 'value': 'Maintenance & Repair Services'},
        {'label': 'Industrial Design Services', 'value': 'Industrial Design Services'},
        {'label': 'Translation & Interpretation Services', 'value': 'Translation & Interpretation Services'},
        {'label': 'Testing Laboratories', 'value': 'Testing Laboratories'},
        {'label': 'Call Center Services', 'value': 'Call Center Services'},
        {'label': 'Exhibition & Conference Services', 'value': 'Exhibition & Conference Services'},
        {'label': 'Transaction & Payment Services', 'value': 'Transaction & Payment Services'},
        {'label': 'Business Support Supplies (NEC)', 'value': 'Business Support Supplies (NEC)'},
        {'label': 'Office Furniture', 'value': 'Office Furniture'},
        {'label': 'Office Supplies', 'value': 'Office Supplies'},
        {'label': 'Health, Safety & Fire Protection Equipment', 'value': 'Health, Safety & Fire Protection Equipment'},
        {'label': 'Office Supplies Wholesale', 'value': 'Office Supplies Wholesale'},
        {'label': 'Professional Information Services (NEC)', 'value': 'Professional Information Services (NEC)'},
        {'label': 'Financial Information Providers', 'value': 'Financial Information Providers'},
        {'label': 'Compliance & Investor Communication', 'value': 'Compliance & Investor Communication'},
        {'label': 'Rating Agencies', 'value': 'Rating Agencies'},
        {'label': 'Trade & Business Publishing', 'value': 'Trade & Business Publishing'},
        {'label': 'Legal & Tax Information Providers', 'value': 'Legal & Tax Information Providers'},
        {'label': 'Education & Training Information Providers', 'value': 'Education & Training Information Providers'},
        {'label': 'Journals & Scholarly Research', 'value': 'Journals & Scholarly Research'},
        {'label': 'News Agencies', 'value': 'News Agencies'},
        {'label': 'Libraries & Archives', 'value': 'Libraries & Archives'},
        {'label': 'Courier, Postal, Air Freight & Land-based Logistics (NEC)', 'value': 'Courier, Postal, Air Freight & Land-based Logistics (NEC)'},
        {'label': 'Freight Logistics', 'value': 'Freight Logistics'},
        {'label': 'Air Freight', 'value': 'Air Freight'},
        {'label': 'Courier Services', 'value': 'Courier Services'},
        {'label': 'Integrated Logistics Operators', 'value': 'Integrated Logistics Operators'},
        {'label': 'Marine Freight & Logistics (NEC)', 'value': 'Marine Freight & Logistics (NEC)'},
        {'label': 'Marine Logistics', 'value': 'Marine Logistics'},
        {'label': 'Inland Water Freight', 'value': 'Inland Water Freight'},
        {'label': 'Deep Sea Freight', 'value': 'Deep Sea Freight'},
        {'label': 'Ground Freight & Logistics (NEC)', 'value': 'Ground Freight & Logistics (NEC)'},
        {'label': 'Railway Freight Operators', 'value': 'Railway Freight Operators'},
        {'label': 'Freight Trucking', 'value': 'Freight Trucking'},
        {'label': 'Warehousing', 'value': 'Warehousing'},
        {'label': 'Truck Rental', 'value': 'Truck Rental'},
        {'label': 'Airlines (NEC)', 'value': 'Airlines (NEC)'},
        {'label': 'Regional Airlines', 'value': 'Regional Airlines'},
        {'label': 'Charter & Private Air Services', 'value': 'Charter & Private Air Services'},
        {'label': 'Specialized Aviation Services', 'value': 'Specialized Aviation Services'},
        {'label': 'Inter-Modal Passenger Transportation', 'value': 'Inter-Modal Passenger Transportation'},
        {'label': 'Passenger Transportation, Ground & Sea (NEC)', 'value': 'Passenger Transportation, Ground & Sea (NEC)'},
        {'label': 'Commuting Services', 'value': 'Commuting Services'},
        {'label': 'Charter Bus Services', 'value': 'Charter Bus Services'},
        {'label': 'Rail Services', 'value': 'Rail Services'},
        {'label': 'Marine Passenger Transportation', 'value': 'Marine Passenger Transportation'},
        {'label': 'Commuter Ferry Operators', 'value': 'Commuter Ferry Operators'},
        {'label': 'Taxis & Limousines', 'value': 'Taxis & Limousines'},
        {'label': 'Passenger Car Rental', 'value': 'Passenger Car Rental'},
        {'label': 'Airport Operators & Services (NEC)', 'value': 'Airport Operators & Services (NEC)'},
        {'label': 'Airport Operators', 'value': 'Airport Operators'},
        {'label': 'Duty Free Shops', 'value': 'Duty Free Shops'},
        {'label': 'Airport Fueling Services', 'value': 'Airport Fueling Services'},
        {'label': 'Airline Catering Services', 'value': 'Airline Catering Services'},
        {'label': 'Marine Port Services (NEC)', 'value': 'Marine Port Services (NEC)'},
        {'label': 'Port Warehousing Services', 'value': 'Port Warehousing Services'},
        {'label': 'Port Operators', 'value': 'Port Operators'},
        {'label': 'Marine Cargo Handling Services', 'value': 'Marine Cargo Handling Services'},
        {'label': 'Highways & Rail Tracks (NEC)', 'value': 'Highways & Rail Tracks (NEC)'},
        {'label': 'Highway Operators', 'value': 'Highway Operators'},
        {'label': 'Railway Operators', 'value': 'Railway Operators'},
        {'label': 'Parking Lot Operators', 'value': 'Parking Lot Operators'},
        {'label': 'Auto & Truck Manufacturers (NEC)', 'value': 'Auto & Truck Manufacturers (NEC)'},
        {'label': 'Motorcycles & Scooters', 'value': 'Motorcycles & Scooters'},
        {'label': 'Automobiles & Multi Utility Vehicles', 'value': 'Automobiles & Multi Utility Vehicles'},
        {'label': 'Light Trucks', 'value': 'Light Trucks'},
        {'label': 'Electric (Alternative) Vehicles', 'value': 'Electric (Alternative) Vehicles'},
        {'label': 'Luxury Vehicles', 'value': 'Luxury Vehicles'},
        {'label': 'Auto & Truck Wholesale', 'value': 'Auto & Truck Wholesale'},
        {'label': 'Auto, Truck & Motorcycle Parts (NEC)', 'value': 'Auto, Truck & Motorcycle Parts (NEC)'},
        {'label': 'Automotive Body Parts', 'value': 'Automotive Body Parts'},
        {'label': 'Engine & Powertrain Systems', 'value': 'Engine & Powertrain Systems'},
        {'label': 'Automotive Batteries', 'value': 'Automotive Batteries'},
        {'label': 'Automotive Systems', 'value': 'Automotive Systems'},
        {'label': 'Automotive Accessories', 'value': 'Automotive Accessories'},
        {'label': 'Motorcycle Parts & Accessories', 'value': 'Motorcycle Parts & Accessories'},
        {'label': 'Auto & Truck Parts Wholesale', 'value': 'Auto & Truck Parts Wholesale'},
        {'label': 'Tires & Rubber Products (NEC)', 'value': 'Tires & Rubber Products (NEC)'},
        {'label': 'Tire & Tube Manufacturers', 'value': 'Tire & Tube Manufacturers'},
        {'label': 'Tire Retreading', 'value': 'Tire Retreading'},
        {'label': 'Industrial Rubber Products', 'value': 'Industrial Rubber Products'},
        {'label': 'Rubber Plantations', 'value': 'Rubber Plantations'},
        {'label': 'Tires & Rubber Products Wholesale', 'value': 'Tires & Rubber Products Wholesale'},
        {'label': 'Textiles & Leather Goods (NEC)', 'value': 'Textiles & Leather Goods (NEC)'},
        {'label': 'Synthetic Fabrics', 'value': 'Synthetic Fabrics'},
        {'label': 'Natural Fabrics', 'value': 'Natural Fabrics'},
        {'label': 'Organic & Ecologically Produced Fabric', 'value': 'Organic & Ecologically Produced Fabric'},
        {'label': 'Leather Goods', 'value': 'Leather Goods'},
        {'label': 'Fur Goods', 'value': 'Fur Goods'},
        {'label': 'Fabric Dyeing & Finishing', 'value': 'Fabric Dyeing & Finishing'},
        {'label': 'Yarn Goods', 'value': 'Yarn Goods'},
        {'label': 'Cotton Farming', 'value': 'Cotton Farming'},
        {'label': 'Textiles & Leather Goods Wholesale', 'value': 'Textiles & Leather Goods Wholesale'},
        {'label': 'Apparel & Accessories (NEC)', 'value': 'Apparel & Accessories (NEC)'},
        {'label': 'Men\'s Clothing', 'value': 'Men\'s Clothing'},
        {'label': 'Women\'s Clothing', 'value': 'Women\'s Clothing'},
        {'label': 'Children\'s & Infants\' Clothing', 'value': 'Children\'s & Infants\' Clothing'},
        {'label': 'Sportswear & Outdoors Clothing', 'value': 'Sportswear & Outdoors Clothing'},
        {'label': 'Jeans', 'value': 'Jeans'},
        {'label': 'Knitwear', 'value': 'Knitwear'},
        {'label': 'Lingerie', 'value': 'Lingerie'},
        {'label': 'Hosiery & Socks', 'value': 'Hosiery & Socks'},
        {'label': 'Industrial Clothing & Uniforms', 'value': 'Industrial Clothing & Uniforms'},
        {'label': 'Fair Trade & Ethical Clothing', 'value': 'Fair Trade & Ethical Clothing'},
        {'label': 'Luxury Clothing', 'value': 'Luxury Clothing'},
        {'label': 'Theatrical Costumes', 'value': 'Theatrical Costumes'},
        {'label': 'Animal & Pet Clothing', 'value': 'Animal & Pet Clothing'},
        {'label': 'Luxury Accessories', 'value': 'Luxury Accessories'},
        {'label': 'Accessories', 'value': 'Accessories'},
        {'label': 'Jewelry', 'value': 'Jewelry'},
        {'label': 'Watches', 'value': 'Watches'},
        {'label': 'Handbags & Luggage', 'value': 'Handbags & Luggage'},
        {'label': 'Fashion Eyewear', 'value': 'Fashion Eyewear'},
        {'label': 'Apparel Wholesale', 'value': 'Apparel Wholesale'},
        {'label': 'Footwear (NEC)', 'value': 'Footwear (NEC)'},
        {'label': 'Men\'s Footwear', 'value': 'Men\'s Footwear'},
        {'label': 'Women\'s Footwear', 'value': 'Women\'s Footwear'},
        {'label': 'Children\'s & Infants\' Footwear', 'value': 'Children\'s & Infants\' Footwear'},
        {'label': 'Sports & Outdoor Footwear', 'value': 'Sports & Outdoor Footwear'},
        {'label': 'Luxury Footwear', 'value': 'Luxury Footwear'},
        {'label': 'Functional Footwear', 'value': 'Functional Footwear'},
        {'label': 'Footwear Wholesale', 'value': 'Footwear Wholesale'},
        {'label': 'Homebuilding (NEC)', 'value': 'Homebuilding (NEC)'},
        {'label': 'Residential Builders - Single Homes', 'value': 'Residential Builders - Single Homes'},
        {'label': 'Residential Builders - Multifamily Homes', 'value': 'Residential Builders - Multifamily Homes'},
        {'label': 'Prefabricated Homes', 'value': 'Prefabricated Homes'},
        {'label': 'Sustainable & Energy Efficient Home Builders', 'value': 'Sustainable & Energy Efficient Home Builders'},
        {'label': 'Retirement Home Builders', 'value': 'Retirement Home Builders'},
        {'label': 'Residential Architectural & Interior Design Services', 'value': 'Residential Architectural & Interior Design Services'},
        {'label': 'Construction Supplies & Fixtures (NEC)', 'value': 'Construction Supplies & Fixtures (NEC)'},
        {'label': 'Construction Supplies', 'value': 'Construction Supplies'},
        {'label': 'Luxury Construction Supplies & Fixtures', 'value': 'Luxury Construction Supplies & Fixtures'},
        {'label': 'Doors & Window Frames', 'value': 'Doors & Window Frames'},
        {'label': 'Flooring & Interior Tile Manufacturers', 'value': 'Flooring & Interior Tile Manufacturers'},
        {'label': 'Plumbing Fixtures & Fittings', 'value': 'Plumbing Fixtures & Fittings'},
        {'label': 'Kitchen Cabinets', 'value': 'Kitchen Cabinets'},
        {'label': 'Bathroom Fixtures', 'value': 'Bathroom Fixtures'},
        {'label': 'Roofing Supplies', 'value': 'Roofing Supplies'},
        {'label': 'Lighting Fixtures', 'value': 'Lighting Fixtures'},
        {'label': 'Construction Supplies & Fixtures Wholesale', 'value': 'Construction Supplies & Fixtures Wholesale'},
        {'label': 'Appliances, Tools & Housewares (NEC)', 'value': 'Appliances, Tools & Housewares (NEC)'},
        {'label': 'Household Appliances', 'value': 'Household Appliances'},
        {'label': 'Tools & Housewares', 'value': 'Tools & Housewares'},
        {'label': 'Kitchen Appliances', 'value': 'Kitchen Appliances'},
        {'label': 'Cutlery & Flatware', 'value': 'Cutlery & Flatware'},
        {'label': 'Appliance & Houseware Wholesale', 'value': 'Appliance & Houseware Wholesale'},
        {'label': 'Luxury Appliances', 'value': 'Luxury Appliances'},
        {'label': 'Home Furnishings (NEC)', 'value': 'Home Furnishings (NEC)'},
        {'label': 'Carpets & Curtains', 'value': 'Carpets & Curtains'},
        {'label': 'Wallpaper', 'value': 'Wallpaper'},
        {'label': 'Luxury Furnishing', 'value': 'Luxury Furnishing'},
        {'label': 'Antiques', 'value': 'Antiques'},
        {'label': 'Home Furnishings Wholesale', 'value': 'Home Furnishings Wholesale'},
        {'label': 'Furniture', 'value': 'Furniture'},
        {'label': 'Art & Craftwork', 'value': 'Art & Craftwork'},
        {'label': 'Toys & Children\'s Products (NEC)', 'value': 'Toys & Children\'s Products (NEC)'},
        {'label': 'Dolls & Stuffed Toys', 'value': 'Dolls & Stuffed Toys'},
        {'label': 'Games, Toys & Children\'s Vehicles', 'value': 'Games, Toys & Children\'s Vehicles'},
        {'label': 'Children\'s Safety Products', 'value': 'Children\'s Safety Products'},
        {'label': 'Children\'s Furniture', 'value': 'Children\'s Furniture'},
        {'label': 'Children\'s Products & Accessories', 'value': 'Children\'s Products & Accessories'},
        {'label': 'Toys & Children\'s Products Wholesale', 'value': 'Toys & Children\'s Products Wholesale'},
        {'label': 'Recreational Products (NEC)', 'value': 'Recreational Products (NEC)'},
        {'label': 'Sailing Yachts & Motorboats', 'value': 'Sailing Yachts & Motorboats'},
        {'label': 'Bicycle Manufacturing', 'value': 'Bicycle Manufacturing'},
        {'label': 'Sporting & Outdoor Goods', 'value': 'Sporting & Outdoor Goods'},
        {'label': 'Musical Instruments', 'value': 'Musical Instruments'},
        {'label': 'Luxury Recreational Products', 'value': 'Luxury Recreational Products'},
        {'label': 'Leisure Products Wholesale', 'value': 'Leisure Products Wholesale'},
        {'label': 'Electric Scooters & Bicycles', 'value': 'Electric Scooters & Bicycles'},
        {'label': 'Hotels, Motels & Cruise Lines (NEC)', 'value': 'Hotels, Motels & Cruise Lines (NEC)'},
        {'label': 'Hotels & Motels', 'value': 'Hotels & Motels'},
        {'label': 'Cruise Lines', 'value': 'Cruise Lines'},
        {'label': 'Luxury Hotels', 'value': 'Luxury Hotels'},
        {'label': 'Resort Operators', 'value': 'Resort Operators'},
        {'label': 'Bed & Breakfast', 'value': 'Bed & Breakfast'},
        {'label': 'Self-Catering Accommodation', 'value': 'Self-Catering Accommodation'},
        {'label': 'Campsite Operators', 'value': 'Campsite Operators'},
        {'label': 'Restaurants & Bars (NEC)', 'value': 'Restaurants & Bars (NEC)'},
        {'label': 'Pubs, Bars & Night Clubs', 'value': 'Pubs, Bars & Night Clubs'},
        {'label': 'Commercial Food Services', 'value': 'Commercial Food Services'},
        {'label': 'Quick Service Restaurants', 'value': 'Quick Service Restaurants'},
        {'label': 'Mobile Caterers', 'value': 'Mobile Caterers'},
        {'label': 'Banquet Halls & Catering', 'value': 'Banquet Halls & Catering'},
        {'label': 'Cafés', 'value': 'Cafés'},
        {'label': 'Casinos & Gaming (NEC)', 'value': 'Casinos & Gaming (NEC)'},
        {'label': 'Gambling & Gaming Machine Manufacturers', 'value': 'Gambling & Gaming Machine Manufacturers'},
        {'label': 'Gaming Machine Operators', 'value': 'Gaming Machine Operators'},
        {'label': 'Casinos', 'value': 'Casinos'},
        {'label': 'Horse & Dog Race Tracks', 'value': 'Horse & Dog Race Tracks'},
        {'label': 'Lottery Operators', 'value': 'Lottery Operators'},
        {'label': 'Leisure & Recreation (NEC)', 'value': 'Leisure & Recreation (NEC)'},
        {'label': 'Movie Theaters & Movie Products', 'value': 'Movie Theaters & Movie Products'},
        {'label': 'Theatres & Performing Arts', 'value': 'Theatres & Performing Arts'},
        {'label': 'Museums & Historic Places', 'value': 'Museums & Historic Places'},
        {'label': 'Travel Agents', 'value': 'Travel Agents'},
        {'label': 'Amusement Parks and Zoos', 'value': 'Amusement Parks and Zoos'},
        {'label': 'Gyms, Fitness and Spa Centers', 'value': 'Gyms, Fitness and Spa Centers'},
        {'label': 'Adventure Sports Facilities & Ski Resorts', 'value': 'Adventure Sports Facilities & Ski Resorts'},
        {'label': 'Public Sport Facilities', 'value': 'Public Sport Facilities'},
        {'label': 'Professional Sports Venues', 'value': 'Professional Sports Venues'},
        {'label': 'Golf Courses', 'value': 'Golf Courses'},
        {'label': 'Hunting & Fishing', 'value': 'Hunting & Fishing'},
        {'label': 'Marinas', 'value': 'Marinas'},
        {'label': 'Guided Tour Operators', 'value': 'Guided Tour Operators'},
        {'label': 'Consumer Publishing (NEC)', 'value': 'Consumer Publishing (NEC)'},
        {'label': 'Newspaper Publishing', 'value': 'Newspaper Publishing'},
        {'label': 'Magazine Publishing', 'value': 'Magazine Publishing'},
        {'label': 'Book Publishing', 'value': 'Book Publishing'},
        {'label': 'Directory Publishing', 'value': 'Directory Publishing'},
        {'label': 'Digital Publishing', 'value': 'Digital Publishing'},
        {'label': 'Adult Publishing', 'value': 'Adult Publishing'},
        {'label': 'Books, Newspapers & Magazines Wholesale', 'value': 'Books, Newspapers & Magazines Wholesale'},
        {'label': 'Department Stores (NEC)', 'value': 'Department Stores (NEC)'},
        {'label': 'General Department Stores', 'value': 'General Department Stores'},
        {'label': 'Luxury Department Stores', 'value': 'Luxury Department Stores'},
        {'label': 'Internet & Mail Order Department Stores', 'value': 'Internet & Mail Order Department Stores'},
        {'label': 'Discount Stores (NEC)', 'value': 'Discount Stores (NEC)'},
        {'label': 'Internet & Mail Order Discount Stores', 'value': 'Internet & Mail Order Discount Stores'},
        {'label': 'Discount Stores with Grocery', 'value': 'Discount Stores with Grocery'},
        {'label': 'Discount Stores without Grocery', 'value': 'Discount Stores without Grocery'},
        {'label': 'Discount Stores with Gasoline', 'value': 'Discount Stores with Gasoline'},
        {'label': 'Discount Stores without Gasoline', 'value': 'Discount Stores without Gasoline'},
        {'label': 'Auto Vehicles, Parts & Service Retailers (NEC)', 'value': 'Auto Vehicles, Parts & Service Retailers (NEC)'},
        {'label': 'New Car Dealers', 'value': 'New Car Dealers'},
        {'label': 'Used Car Dealers', 'value': 'Used Car Dealers'},
        {'label': 'Motorcycle Dealers', 'value': 'Motorcycle Dealers'},
        {'label': 'Automotive Parts & Accessories Retailers', 'value': 'Automotive Parts & Accessories Retailers'},
        {'label': 'Tire Dealers', 'value': 'Tire Dealers'},
        {'label': 'Luxury Car Dealers', 'value': 'Luxury Car Dealers'},
        {'label': 'Home Improvement Products & Services Retailers (NEC)', 'value': 'Home Improvement Products & Services Retailers (NEC)'},
        {'label': 'Paint & Wallpaper Retailers', 'value': 'Paint & Wallpaper Retailers'},
        {'label': 'Builder Merchants', 'value': 'Builder Merchants'},
        {'label': 'Nursery & Garden Centers', 'value': 'Nursery & Garden Centers'},
        {'label': 'Kitchen & Bathroom Retailers', 'value': 'Kitchen & Bathroom Retailers'},
        {'label': 'Home Décor Retailers', 'value': 'Home Décor Retailers'},
        {'label': 'Interior Design Services', 'value': 'Interior Design Services'},
        {'label': 'Luxury Home Improvement Product Retailers', 'value': 'Luxury Home Improvement Product Retailers'},
        {'label': 'Home Furnishings Retailers (NEC)', 'value': 'Home Furnishings Retailers (NEC)'},
        {'label': 'Furniture Retailers', 'value': 'Furniture Retailers'},
        {'label': 'Floor Covering Retailers', 'value': 'Floor Covering Retailers'},
        {'label': 'Soft Furnishing Retailers', 'value': 'Soft Furnishing Retailers'},
        {'label': 'Luxury Furnishing Retailers', 'value': 'Luxury Furnishing Retailers'},
        {'label': 'Antique Dealers', 'value': 'Antique Dealers'},
        {'label': 'Art & Craftwork Retailers', 'value': 'Art & Craftwork Retailers'},
        {'label': 'Apparel & Accessories Retailers (NEC)', 'value': 'Apparel & Accessories Retailers (NEC)'},
        {'label': 'Footwear Retailers', 'value': 'Footwear Retailers'},
        {'label': 'Jewelry & Watch Retailers', 'value': 'Jewelry & Watch Retailers'},
        {'label': 'Men\'s Apparel Retailers', 'value': 'Men\'s Apparel Retailers'},
        {'label': 'Women\'s Apparel Retailers', 'value': 'Women\'s Apparel Retailers'},
        {'label': 'Children\'s & Infants\' Clothing Retailers', 'value': 'Children\'s & Infants\' Clothing Retailers'},
        {'label': 'Teen Fashion Retailers', 'value': 'Teen Fashion Retailers'},
        {'label': 'Handbags & Luggage Retailers', 'value': 'Handbags & Luggage Retailers'},
        {'label': 'Luxury Apparel Retailers', 'value': 'Luxury Apparel Retailers'},
        {'label': 'Sports & Outdoors Retailers', 'value': 'Sports & Outdoors Retailers'},
        {'label': 'Computer & Electronics Retailers (NEC)', 'value': 'Computer & Electronics Retailers (NEC)'},
        {'label': 'Computer Hardware & Software Retailers', 'value': 'Computer Hardware & Software Retailers'},
        {'label': 'Consumer Electronics Retailers', 'value': 'Consumer Electronics Retailers'},
        {'label': 'Mobile Phone Retailers', 'value': 'Mobile Phone Retailers'},
        {'label': 'Miscellaneous Specialty Retailers (NEC)', 'value': 'Miscellaneous Specialty Retailers (NEC)'},
        {'label': 'Luxury Beauty Supply Retailers', 'value': 'Luxury Beauty Supply Retailers'},
        {'label': 'Personal Care Products Retailers', 'value': 'Personal Care Products Retailers'},
        {'label': 'Optical Goods Stores', 'value': 'Optical Goods Stores'},
        {'label': 'Health Food Stores', 'value': 'Health Food Stores'},
        {'label': 'Musical Instrument Retailers', 'value': 'Musical Instrument Retailers'},
        {'label': 'Hobby & Craft Product Retailers', 'value': 'Hobby & Craft Product Retailers'},
        {'label': 'Toys & Games Retailers', 'value': 'Toys & Games Retailers'},
        {'label': 'Book & Magazine Retailers', 'value': 'Book & Magazine Retailers'},
        {'label': 'Florists', 'value': 'Florists'},
        {'label': 'Office Supplies & Stationery Stores', 'value': 'Office Supplies & Stationery Stores'},
        {'label': 'Gift, Novelty & Souvenir Stores', 'value': 'Gift, Novelty & Souvenir Stores'},
        {'label': 'Used Merchandise Stores', 'value': 'Used Merchandise Stores'},
        {'label': 'Sporting Goods Stores', 'value': 'Sporting Goods Stores'},
        {'label': 'Pet & Pet Supplies Retailers', 'value': 'Pet & Pet Supplies Retailers'},
        {'label': 'Adult Products Retailers', 'value': 'Adult Products Retailers'},
        {'label': 'Brewers (NEC)', 'value': 'Brewers (NEC)'},
        {'label': 'Craft & Micro Brewers', 'value': 'Craft & Micro Brewers'},
        {'label': 'Distillers & Wineries (NEC)', 'value': 'Distillers & Wineries (NEC)'},
        {'label': 'Wineries', 'value': 'Wineries'},
        {'label': 'Distilleries', 'value': 'Distilleries'},
        {'label': 'Malt Producers', 'value': 'Malt Producers'},
        {'label': 'Non-Alcoholic Beverages (NEC)', 'value': 'Non-Alcoholic Beverages (NEC)'},
        {'label': 'Carbonated Soft Drinks', 'value': 'Carbonated Soft Drinks'},
        {'label': 'Fruit Drinks', 'value': 'Fruit Drinks'},
        {'label': 'Energy Drinks', 'value': 'Energy Drinks'},
        {'label': 'Bottled Water & Ice', 'value': 'Bottled Water & Ice'},
        {'label': 'Fishing & Farming (NEC)', 'value': 'Fishing & Farming (NEC)'},
        {'label': 'Grain (Crop) Production', 'value': 'Grain (Crop) Production'},
        {'label': 'Poultry Farming', 'value': 'Poultry Farming'},
        {'label': 'Sheep & Specialty Livestock Farming', 'value': 'Sheep & Specialty Livestock Farming'},
        {'label': 'Vegetable, Fruit & Nut Farming', 'value': 'Vegetable, Fruit & Nut Farming'},
        {'label': 'Coffee, Tea & Cocoa Farming', 'value': 'Coffee, Tea & Cocoa Farming'},
        {'label': 'Sugarcane Farming', 'value': 'Sugarcane Farming'},
        {'label': 'Commercial Nurseries', 'value': 'Commercial Nurseries'},
        {'label': 'Commercial Fishing', 'value': 'Commercial Fishing'},
        {'label': 'Aquaculture', 'value': 'Aquaculture'},
        {'label': 'Fur Farming', 'value': 'Fur Farming'},
        {'label': 'Animal Breeding', 'value': 'Animal Breeding'},
        {'label': 'Agriculture Support Services', 'value': 'Agriculture Support Services'},
        {'label': 'Organic Farming', 'value': 'Organic Farming'},
        {'label': 'Animal Feed', 'value': 'Animal Feed'},
        {'label': 'Agricultural Consultancy Services', 'value': 'Agricultural Consultancy Services'},
        {'label': 'Fishing & Farming Wholesale', 'value': 'Fishing & Farming Wholesale'},
        {'label': 'Agricultural Biotechnology', 'value': 'Agricultural Biotechnology'},
        {'label': 'Hog & Pig Farming', 'value': 'Hog & Pig Farming'},
        {'label': 'Cattle Farming', 'value': 'Cattle Farming'},
        {'label': 'Fair Trade & Ethical Fishing & Farming', 'value': 'Fair Trade & Ethical Fishing & Farming'},
        {'label': 'Food Processing (NEC)', 'value': 'Food Processing (NEC)'},
        {'label': 'Flour Milling', 'value': 'Flour Milling'},
        {'label': 'Bread & Bakery Product Manufacturing', 'value': 'Bread & Bakery Product Manufacturing'},
        {'label': 'Breakfast Cereal Manufacturing', 'value': 'Breakfast Cereal Manufacturing'},
        {'label': 'Cookie, Cracker & Pasta Manufacturing', 'value': 'Cookie, Cracker & Pasta Manufacturing'},
        {'label': 'Fruit & Vegetable Processing', 'value': 'Fruit & Vegetable Processing'},
        {'label': 'Meat Processing', 'value': 'Meat Processing'},
        {'label': 'Halal Meat Processing', 'value': 'Halal Meat Processing'},
        {'label': 'Seafood Product Preparation & Packaging', 'value': 'Seafood Product Preparation & Packaging'},
        {'label': 'Dairy Products', 'value': 'Dairy Products'},
        {'label': 'Starch, Vegetable Fat & Oil Manufacturing', 'value': 'Starch, Vegetable Fat & Oil Manufacturing'},
        {'label': 'Coffee & Tea', 'value': 'Coffee & Tea'},
        {'label': 'Sugar & Artificial Sweeteners', 'value': 'Sugar & Artificial Sweeteners'},
        {'label': 'Chocolate & Confectionery', 'value': 'Chocolate & Confectionery'},
        {'label': 'Snack Food & Non-Chocolate Confectionary', 'value': 'Snack Food & Non-Chocolate Confectionary'},
        {'label': 'Special Foods & Wellbeing Products', 'value': 'Special Foods & Wellbeing Products'},
        {'label': 'Food Ingredients', 'value': 'Food Ingredients'},
        {'label': 'Baby Food', 'value': 'Baby Food'},
        {'label': 'Ready-Made Meals', 'value': 'Ready-Made Meals'},
        {'label': 'Frozen Food Manufacturing', 'value': 'Frozen Food Manufacturing'},
        {'label': 'Pet Food Manufacturing', 'value': 'Pet Food Manufacturing'},
        {'label': 'Vegan & Vegetarian Food Manufacturing', 'value': 'Vegan & Vegetarian Food Manufacturing'},
        {'label': 'Tobacco (NEC)', 'value': 'Tobacco (NEC)'},
        {'label': 'Tobacco Farming', 'value': 'Tobacco Farming'},
        {'label': 'Tobacco Stemming & Redrying', 'value': 'Tobacco Stemming & Redrying'},
        {'label': 'Cigars & Cigarette Manufacturing', 'value': 'Cigars & Cigarette Manufacturing'},
        {'label': 'Chewing Tobacco Products', 'value': 'Chewing Tobacco Products'},
        {'label': 'Household Products (NEC)', 'value': 'Household Products (NEC)'},
        {'label': 'Laundry Supplies', 'value': 'Laundry Supplies'},
        {'label': 'Cleaning Supplies', 'value': 'Cleaning Supplies'},
        {'label': 'Air Fresheners', 'value': 'Air Fresheners'},
        {'label': 'Pet & Plant Protection Agents', 'value': 'Pet & Plant Protection Agents'},
        {'label': 'Auto Cleaning Products', 'value': 'Auto Cleaning Products'},
        {'label': 'Personal Products (NEC)', 'value': 'Personal Products (NEC)'},
        {'label': 'Cosmetics & Perfumes', 'value': 'Cosmetics & Perfumes'},
        {'label': 'Luxury Cosmetics', 'value': 'Luxury Cosmetics'},
        {'label': 'Sanitary Products', 'value': 'Sanitary Products'},
        {'label': 'Hair Accessories', 'value': 'Hair Accessories'},
        {'label': 'Birth Control Products', 'value': 'Birth Control Products'},
        {'label': 'Halal Personal Products', 'value': 'Halal Personal Products'},
        {'label': 'Personal Services (NEC)', 'value': 'Personal Services (NEC)'},
        {'label': 'Consumer Goods Rental', 'value': 'Consumer Goods Rental'},
        {'label': 'Accounting & Tax Preparation', 'value': 'Accounting & Tax Preparation'},
        {'label': 'Personal Legal Services', 'value': 'Personal Legal Services'},
        {'label': 'Child Care & Family Services', 'value': 'Child Care & Family Services'},
        {'label': 'Consumer Repair Services', 'value': 'Consumer Repair Services'},
        {'label': 'Personal Care Services', 'value': 'Personal Care Services'},
        {'label': 'Funeral Services', 'value': 'Funeral Services'},
        {'label': 'Drug Retailers (NEC)', 'value': 'Drug Retailers (NEC)'},
        {'label': 'Retail - Drugs with Grocery', 'value': 'Retail - Drugs with Grocery'},
        {'label': 'Retail - Drugs without Grocery', 'value': 'Retail - Drugs without Grocery'},
        {'label': 'Cannabis Product Retailers', 'value': 'Cannabis Product Retailers'},
        {'label': 'Non-Cannabis Recreational Drug Retailers', 'value': 'Non-Cannabis Recreational Drug Retailers'},
        {'label': 'Food Retail & Distribution (NEC)', 'value': 'Food Retail & Distribution (NEC)'},
        {'label': 'Food Wholesale', 'value': 'Food Wholesale'},
        {'label': 'Supermarkets & Convenience Stores', 'value': 'Supermarkets & Convenience Stores'},
        {'label': 'Beer, Wine & Liquor Stores', 'value': 'Beer, Wine & Liquor Stores'},
        {'label': 'Vending Machine Providers', 'value': 'Vending Machine Providers'},
        {'label': 'Tobacco Stores', 'value': 'Tobacco Stores'},
        {'label': 'Food Markets', 'value': 'Food Markets'},
        {'label': 'Consumer Goods Conglomerates', 'value': 'Consumer Goods Conglomerates'},
        {'label': 'Banks (NEC)', 'value': 'Banks (NEC)'},
        {'label': 'Corporate Banks', 'value': 'Corporate Banks'},
        {'label': 'Retail & Mortgage Banks', 'value': 'Retail & Mortgage Banks'},
        {'label': 'Money Center Banks', 'value': 'Money Center Banks'},
        {'label': 'Private Banks', 'value': 'Private Banks'},
        {'label': 'Islamic Banks', 'value': 'Islamic Banks'},
        {'label': 'Consumer Lending (NEC)', 'value': 'Consumer Lending (NEC)'},
        {'label': 'Personal & Car Loans', 'value': 'Personal & Car Loans'},
        {'label': 'Consumer Credit Cards Services', 'value': 'Consumer Credit Cards Services'},
        {'label': 'Consumer Leasing', 'value': 'Consumer Leasing'},
        {'label': 'Credit Unions', 'value': 'Credit Unions'},
        {'label': 'Microfinancing', 'value': 'Microfinancing'},
        {'label': 'Corporate Financial Services (NEC)', 'value': 'Corporate Financial Services (NEC)'},
        {'label': 'Commercial Loans', 'value': 'Commercial Loans'},
        {'label': 'Import-Export Banks', 'value': 'Import-Export Banks'},
        {'label': 'International Trade Financing', 'value': 'International Trade Financing'},
        {'label': 'Factoring', 'value': 'Factoring'},
        {'label': 'Commercial Leasing', 'value': 'Commercial Leasing'},
        {'label': 'Investment Banking & Brokerage Services (NEC)', 'value': 'Investment Banking & Brokerage Services (NEC)'},
        {'label': 'Investment Banking', 'value': 'Investment Banking'},
        {'label': 'Brokerage Services', 'value': 'Brokerage Services'},
        {'label': 'Inter-Dealer Broker', 'value': 'Inter-Dealer Broker'},
        {'label': 'Islamic Investment Banking & Brokerage Services', 'value': 'Islamic Investment Banking & Brokerage Services'},
        {'label': 'Merchant Banks', 'value': 'Merchant Banks'},
        {'label': 'Investment Management & Fund Operators (NEC)', 'value': 'Investment Management & Fund Operators (NEC)'},
        {'label': 'Investment Management', 'value': 'Investment Management'},
        {'label': 'Hedge Funds', 'value': 'Hedge Funds'},
        {'label': 'Collective Investment Fund Operators', 'value': 'Collective Investment Fund Operators'},
        {'label': 'Wealth Management', 'value': 'Wealth Management'},
        {'label': 'Venture Capital', 'value': 'Venture Capital'},
        {'label': 'Private Equity', 'value': 'Private Equity'},
        {'label': 'Islamic Investment Management & Fund Operators', 'value': 'Islamic Investment Management & Fund Operators'},
        {'label': 'Diversified Investment Services', 'value': 'Diversified Investment Services'},
        {'label': 'Financial & Commodity Market Operators & Service Providers (NEC)', 'value': 'Financial & Commodity Market Operators & Service Providers (NEC)'},
        {'label': 'Securities & Commodity Exchanges', 'value': 'Securities & Commodity Exchanges'},
        {'label': 'Clearing, Settlement & Custodial Service', 'value': 'Clearing, Settlement & Custodial Service'},
        {'label': 'Multiline Insurance & Brokers (NEC)', 'value': 'Multiline Insurance & Brokers (NEC)'},
        {'label': 'Islamic Insurance', 'value': 'Islamic Insurance'},
        {'label': 'Insurance Brokers', 'value': 'Insurance Brokers'},
        {'label': 'Property & Casualty Insurance (NEC)', 'value': 'Property & Casualty Insurance (NEC)'},
        {'label': 'Property Insurance', 'value': 'Property Insurance'},
        {'label': 'Insurance - Automobile', 'value': 'Insurance - Automobile'},
        {'label': 'Travel Insurance', 'value': 'Travel Insurance'},
        {'label': 'Casualty Insurance', 'value': 'Casualty Insurance'},
        {'label': 'Life & Health Insurance (NEC)', 'value': 'Life & Health Insurance (NEC)'},
        {'label': 'Life Insurance', 'value': 'Life Insurance'},
        {'label': 'Health Insurance', 'value': 'Health Insurance'},
        {'label': 'Reinsurance (NEC)', 'value': 'Reinsurance (NEC)'},
        {'label': 'Life & Health Reinsurance', 'value': 'Life & Health Reinsurance'},
        {'label': 'Property & Casualty Reinsurance', 'value': 'Property & Casualty Reinsurance'},
        {'label': 'UK Investment Trusts', 'value': 'UK Investment Trusts'},
        {'label': 'Mutual Funds (NEC)', 'value': 'Mutual Funds (NEC)'},
        {'label': 'Islamic Mutual Funds', 'value': 'Islamic Mutual Funds'},
        {'label': 'Closed End Funds', 'value': 'Closed End Funds'},
        {'label': 'Exchange-Traded Funds (NEC)', 'value': 'Exchange-Traded Funds (NEC)'},
        {'label': 'Islamic ETFs', 'value': 'Islamic ETFs'},
        {'label': 'Islamic Commodity ETFs', 'value': 'Islamic Commodity ETFs'},
        {'label': 'Pension Funds', 'value': 'Pension Funds'},
        {'label': 'Insurance Funds', 'value': 'Insurance Funds'},
        {'label': 'Investment Holding Companies (NEC)', 'value': 'Investment Holding Companies (NEC)'},
        {'label': 'Shell Companies', 'value': 'Shell Companies'},
        {'label': 'Advanced Medical Equipment & Technology (NEC)', 'value': 'Advanced Medical Equipment & Technology (NEC)'},
        {'label': 'Medical Diagnostic & Testing Equipment', 'value': 'Medical Diagnostic & Testing Equipment'},
        {'label': 'Medical Monitoring Systems', 'value': 'Medical Monitoring Systems'},
        {'label': 'Laser Equipment', 'value': 'Laser Equipment'},
        {'label': 'Medical Imaging Systems', 'value': 'Medical Imaging Systems'},
        {'label': 'Medical Software & Technology Services', 'value': 'Medical Software & Technology Services'},
        {'label': 'Advanced Medical Equipment Wholesale', 'value': 'Advanced Medical Equipment Wholesale'},
        {'label': 'Medical Equipment, Supplies & Distribution (NEC)', 'value': 'Medical Equipment, Supplies & Distribution (NEC)'},
        {'label': 'Medical Supplies', 'value': 'Medical Supplies'},
        {'label': 'Medical Prosthetics', 'value': 'Medical Prosthetics'},
        {'label': 'Medical Equipment', 'value': 'Medical Equipment'},
        {'label': 'Medical Devices & Implants', 'value': 'Medical Devices & Implants'},
        {'label': 'Medical Equipment Wholesale', 'value': 'Medical Equipment Wholesale'},
        {'label': 'Glasses, Spectacles & Contact Lenses', 'value': 'Glasses, Spectacles & Contact Lenses'},
        {'label': 'Laboratory Diagnostic & Testing Substances', 'value': 'Laboratory Diagnostic & Testing Substances'},
        {'label': 'Veterinary Medical Equipment & Supplies', 'value': 'Veterinary Medical Equipment & Supplies'},
        {'label': 'Drug Delivery Systems', 'value': 'Drug Delivery Systems'},
        {'label': 'Healthcare Facilities & Services (NEC)', 'value': 'Healthcare Facilities & Services (NEC)'},
        {'label': 'Hospitals, Clinics & Primary Care Services', 'value': 'Hospitals, Clinics & Primary Care Services'},
        {'label': 'Residential & Long-Term Care', 'value': 'Residential & Long-Term Care'},
        {'label': 'Ambulance & Emergency Services', 'value': 'Ambulance & Emergency Services'},
        {'label': 'Medical & Diagnostic Laboratories', 'value': 'Medical & Diagnostic Laboratories'},
        {'label': 'Veterinary Services', 'value': 'Veterinary Services'},
        {'label': 'Telemedicine Services', 'value': 'Telemedicine Services'},
        {'label': 'Home Healthcare Services', 'value': 'Home Healthcare Services'},
        {'label': 'Alternative Medicine Facilities', 'value': 'Alternative Medicine Facilities'},
        {'label': 'Medical Farming', 'value': 'Medical Farming'},
        {'label': 'Managed Healthcare (NEC)', 'value': 'Managed Healthcare (NEC)'},
        {'label': 'HMO Medical Centers', 'value': 'HMO Medical Centers'},
        {'label': 'Pharmaceuticals (NEC)', 'value': 'Pharmaceuticals (NEC)'},
        {'label': 'Proprietary & Advanced Pharmaceuticals', 'value': 'Proprietary & Advanced Pharmaceuticals'},
        {'label': 'Biopharmaceuticals', 'value': 'Biopharmaceuticals'},
        {'label': 'In-Vivo Diagnostic & Testing Substances', 'value': 'In-Vivo Diagnostic & Testing Substances'},
        {'label': 'Veterinary Drugs', 'value': 'Veterinary Drugs'},
        {'label': 'Generic Pharmaceuticals', 'value': 'Generic Pharmaceuticals'},
        {'label': 'Alternative Medicine', 'value': 'Alternative Medicine'},
        {'label': 'Recreational Pharmaceuticals', 'value': 'Recreational Pharmaceuticals'},
        {'label': 'Pharmaceuticals Wholesale', 'value': 'Pharmaceuticals Wholesale'},
        {'label': 'Biotechnology & Medical Research (NEC)', 'value': 'Biotechnology & Medical Research (NEC)'},
        {'label': 'Bio Therapeutic Drugs', 'value': 'Bio Therapeutic Drugs'},
        {'label': 'Bio Diagnostics & Testing', 'value': 'Bio Diagnostics & Testing'},
        {'label': 'Bio Medical Devices', 'value': 'Bio Medical Devices'},
        {'label': 'Semiconductors (NEC)', 'value': 'Semiconductors (NEC)'},
        {'label': 'Integrated Circuits', 'value': 'Integrated Circuits'},
        {'label': 'Memory Chips (RAM)', 'value': 'Memory Chips (RAM)'},
        {'label': 'Processors', 'value': 'Processors'},
        {'label': 'Semiconductor Wholesale', 'value': 'Semiconductor Wholesale'},
        {'label': 'NFC & RFID Systems', 'value': 'NFC & RFID Systems'},
        {'label': 'Semiconductor Equipment & Testing (NEC)', 'value': 'Semiconductor Equipment & Testing (NEC)'},
        {'label': 'Semiconductor Machinery Manufacturing', 'value': 'Semiconductor Machinery Manufacturing'},
        {'label': 'Semiconductor Testing Equipment & Service', 'value': 'Semiconductor Testing Equipment & Service'},
        {'label': 'Semiconductor Equipment Wholesale', 'value': 'Semiconductor Equipment Wholesale'},
        {'label': 'Communications & Networking (NEC)', 'value': 'Communications & Networking (NEC)'},
        {'label': 'Network Equipment', 'value': 'Network Equipment'},
        {'label': 'Security & Surveillance', 'value': 'Security & Surveillance'},
        {'label': 'Conferencing Tools & Systems', 'value': 'Conferencing Tools & Systems'},
        {'label': 'VOIP Equipment & Systems', 'value': 'VOIP Equipment & Systems'},
        {'label': 'Broadcasting Equipment', 'value': 'Broadcasting Equipment'},
        {'label': 'Satellite Communications Network', 'value': 'Satellite Communications Network'},
        {'label': 'Fiber Optic Cable Manufacturing', 'value': 'Fiber Optic Cable Manufacturing'},
        {'label': 'Electronic Equipment & Parts (NEC)', 'value': 'Electronic Equipment & Parts (NEC)'},
        {'label': 'Biometric Products', 'value': 'Biometric Products'},
        {'label': 'Advanced Electronic Equipment', 'value': 'Advanced Electronic Equipment'},
        {'label': 'Display Screens', 'value': 'Display Screens'},
        {'label': 'Electronic Components', 'value': 'Electronic Components'},
        {'label': '3D Printers', 'value': '3D Printers'},
        {'label': 'Office Equipment (NEC)', 'value': 'Office Equipment (NEC)'},
        {'label': 'Commercial Document Management', 'value': 'Commercial Document Management'},
        {'label': 'Office Technology Equipment', 'value': 'Office Technology Equipment'},
        {'label': 'Point of Sale Systems', 'value': 'Point of Sale Systems'},
        {'label': 'Scientific & Precision Equipment', 'value': 'Scientific & Precision Equipment'},
        {'label': 'Office Equipment Wholesale', 'value': 'Office Equipment Wholesale'},
        {'label': 'Computer Hardware (NEC)', 'value': 'Computer Hardware (NEC)'},
        {'label': 'Scientific & Super Computers', 'value': 'Scientific & Super Computers'},
        {'label': 'Laptop & Desktop Computers', 'value': 'Laptop & Desktop Computers'},
        {'label': 'Tablet & Netbook Computers', 'value': 'Tablet & Netbook Computers'},
        {'label': 'Input Devices', 'value': 'Input Devices'},
        {'label': 'Output Devices', 'value': 'Output Devices'},
        {'label': 'Servers & Systems', 'value': 'Servers & Systems'},
        {'label': 'Storage Devices', 'value': 'Storage Devices'},
        {'label': 'Computer Hardware Component Assembly', 'value': 'Computer Hardware Component Assembly'},
        {'label': 'Consumer Document Management', 'value': 'Consumer Document Management'},
        {'label': 'Phones & Handheld Devices (NEC)', 'value': 'Phones & Handheld Devices (NEC)'},
        {'label': 'Phones & Smart Phones', 'value': 'Phones & Smart Phones'},
        {'label': 'Portable Satellite Navigation', 'value': 'Portable Satellite Navigation'},
        {'label': 'Personal Music Players', 'value': 'Personal Music Players'},
        {'label': 'Electronic Books', 'value': 'Electronic Books'},
        {'label': 'Mobile Device Component Assembly', 'value': 'Mobile Device Component Assembly'},
        {'label': 'Household Electronics (NEC)', 'value': 'Household Electronics (NEC)'},
        {'label': 'Photographic Equipment', 'value': 'Photographic Equipment'},
        {'label': 'TV & Video', 'value': 'TV & Video'},
        {'label': 'Home Audio', 'value': 'Home Audio'},
        {'label': 'Consumer Electronic Wholesale', 'value': 'Consumer Electronic Wholesale'},
        {'label': 'Integrated Hardware & Software', 'value': 'Integrated Hardware & Software'},
        {'label': 'IT Services & Consulting (NEC)', 'value': 'IT Services & Consulting (NEC)'},
        {'label': 'Computer Programming', 'value': 'Computer Programming'},
        {'label': 'Computer Training', 'value': 'Computer Training'},
        {'label': 'Technology Consulting & Outsourcing Services', 'value': 'Technology Consulting & Outsourcing Services'},
        {'label': 'IT Testing Services', 'value': 'IT Testing Services'},
        {'label': 'Cloud Computing Services', 'value': 'Cloud Computing Services'},
        {'label': 'Machine Learning & Artificial Intelligence (AI) Services', 'value': 'Machine Learning & Artificial Intelligence (AI) Services'},
        {'label': 'Software (NEC)', 'value': 'Software (NEC)'},
        {'label': 'System Software', 'value': 'System Software'},
        {'label': 'Application Software', 'value': 'Application Software'},
        {'label': 'Enterprise Software', 'value': 'Enterprise Software'},
        {'label': 'Mobile Application Software', 'value': 'Mobile Application Software'},
        {'label': 'Mobile System Software', 'value': 'Mobile System Software'},
        {'label': 'Programming Software & Testing Tools', 'value': 'Programming Software & Testing Tools'},
        {'label': 'Server & Database Software', 'value': 'Server & Database Software'},
        {'label': 'Security Software', 'value': 'Security Software'},
        {'label': 'Online Services (NEC)', 'value': 'Online Services (NEC)'},
        {'label': 'Search Engines', 'value': 'Search Engines'},
        {'label': 'Social Media & Networking', 'value': 'Social Media & Networking'},
        {'label': 'E-commerce & Auction Services', 'value': 'E-commerce & Auction Services'},
        {'label': 'Content & Site Management Services', 'value': 'Content & Site Management Services'},
        {'label': 'Internet Security & Transactions Services', 'value': 'Internet Security & Transactions Services'},
        {'label': 'Internet Gaming', 'value': 'Internet Gaming'},
        {'label': 'Financial Technology (Fintech) (NEC)', 'value': 'Financial Technology (Fintech) (NEC)'},
        {'label': 'Business to Business', 'value': 'Business to Business'},
        {'label': 'Business to Consumer', 'value': 'Business to Consumer'},
        {'label': 'Consumer to Consumer', 'value': 'Consumer to Consumer'},
        {'label': 'Crowd Collaboration (NEC)', 'value': 'Crowd Collaboration (NEC)'},
        {'label': 'Crowdfinancing & Crowdfunding', 'value': 'Crowdfinancing & Crowdfunding'},
        {'label': 'Crowdsourcing Platforms', 'value': 'Crowdsourcing Platforms'},
        {'label': 'Blockchain & Cryptocurrency (NEC)', 'value': 'Blockchain & Cryptocurrency (NEC)'},
        {'label': 'Cryptocurrency Trading Platforms (Exchanges)', 'value': 'Cryptocurrency Trading Platforms (Exchanges)'},
        {'label': 'Blockchain Technology (Software)', 'value': 'Blockchain Technology (Software)'},
        {'label': 'Cryptocurrency Hardware', 'value': 'Cryptocurrency Hardware'},
        {'label': 'Cryptocurrency Mining', 'value': 'Cryptocurrency Mining'},
        {'label': 'Miscellaneous Fintech Infrastructure', 'value': 'Miscellaneous Fintech Infrastructure'},
        {'label': 'Integrated Telecommunications Services (NEC)', 'value': 'Integrated Telecommunications Services (NEC)'},
        {'label': 'Wired Telecommunications Carriers', 'value': 'Wired Telecommunications Carriers'},
        {'label': 'Telecommunications Resellers', 'value': 'Telecommunications Resellers'},
        {'label': 'Internet Service Providers', 'value': 'Internet Service Providers'},
        {'label': 'Telecommunications Network Infrastructure', 'value': 'Telecommunications Network Infrastructure'},
        {'label': 'VOIP Services', 'value': 'VOIP Services'},
        {'label': 'Wireless Telecommunications Services (NEC)', 'value': 'Wireless Telecommunications Services (NEC)'},
        {'label': 'Alternative Communications Services', 'value': 'Alternative Communications Services'},
        {'label': 'Satellite Service Operators', 'value': 'Satellite Service Operators'},
        {'label': 'Wi-Fi & Wi-Max Providers', 'value': 'Wi-Fi & Wi-Max Providers'},
        {'label': 'Wireless Telecoms Service Providers', 'value': 'Wireless Telecoms Service Providers'},
        {'label': 'Advertising & Marketing (NEC)', 'value': 'Advertising & Marketing (NEC)'},
        {'label': 'Advertising Agency', 'value': 'Advertising Agency'},
        {'label': 'Media Buying Agency', 'value': 'Media Buying Agency'},
        {'label': 'Signs & Advertising Specialty Producers', 'value': 'Signs & Advertising Specialty Producers'},
        {'label': 'Outdoor Advertising', 'value': 'Outdoor Advertising'},
        {'label': 'Direct Marketing', 'value': 'Direct Marketing'},
        {'label': 'Sales Promotions & Events Management', 'value': 'Sales Promotions & Events Management'},
        {'label': 'Guerrilla & Sensory Marketing', 'value': 'Guerrilla & Sensory Marketing'},
        {'label': 'Public Relations', 'value': 'Public Relations'},
        {'label': 'Digital Media Agencies', 'value': 'Digital Media Agencies'},
        {'label': 'Branding & Naming', 'value': 'Branding & Naming'},
        {'label': 'Market Research', 'value': 'Market Research'},
        {'label': 'Marketing Consulting Services', 'value': 'Marketing Consulting Services'},
        {'label': 'Broadcasting (NEC)', 'value': 'Broadcasting (NEC)'},
        {'label': 'Television Broadcasting', 'value': 'Television Broadcasting'},
        {'label': 'Radio Broadcasting', 'value': 'Radio Broadcasting'},
        {'label': 'Cable Service Providers', 'value': 'Cable Service Providers'},
        {'label': 'Entertainment Production (NEC)', 'value': 'Entertainment Production (NEC)'},
        {'label': 'Movie, TV Production & Distribution', 'value': 'Movie, TV Production & Distribution'},
        {'label': 'Music, Music Video Production & Distribution', 'value': 'Music, Music Video Production & Distribution'},
        {'label': 'Plays & Concert Production', 'value': 'Plays & Concert Production'},
        {'label': 'Entertainment Production Equipment & Services', 'value': 'Entertainment Production Equipment & Services'},
        {'label': 'Copyright Management', 'value': 'Copyright Management'},
        {'label': 'Adult Entertainment Production & Broadcasting', 'value': 'Adult Entertainment Production & Broadcasting'},
        {'label': 'Electric Utilities (NEC)', 'value': 'Electric Utilities (NEC)'},
        {'label': 'Fossil Fuel Electric Utilities', 'value': 'Fossil Fuel Electric Utilities'},
        {'label': 'Nuclear Utilities', 'value': 'Nuclear Utilities'},
        {'label': 'Power Charging Stations', 'value': 'Power Charging Stations'},
        {'label': 'Alternative Electric Utilities', 'value': 'Alternative Electric Utilities'},
        {'label': 'Hydroelectric & Tidal Utilities', 'value': 'Hydroelectric & Tidal Utilities'},
        {'label': 'Solar Electric Utilities', 'value': 'Solar Electric Utilities'},
        {'label': 'Wind Electric Utilities', 'value': 'Wind Electric Utilities'},
        {'label': 'Biomass & Waste to Energy Electric Utilities', 'value': 'Biomass & Waste to Energy Electric Utilities'},
        {'label': 'Geothermal Electric Utilities', 'value': 'Geothermal Electric Utilities'},
        {'label': 'Independent Power Producers (NEC)', 'value': 'Independent Power Producers (NEC)'},
        {'label': 'Fossil Fuel IPPs', 'value': 'Fossil Fuel IPPs'},
        {'label': 'Renewable IPPs', 'value': 'Renewable IPPs'},
        {'label': 'Nuclear IPPs', 'value': 'Nuclear IPPs'},
        {'label': 'Natural Gas Utilities (NEC)', 'value': 'Natural Gas Utilities (NEC)'},
        {'label': 'Natural Gas Distribution', 'value': 'Natural Gas Distribution'},
        {'label': 'Water & Related Utilities (NEC)', 'value': 'Water & Related Utilities (NEC)'},
        {'label': 'Water Supply & Irrigation Systems', 'value': 'Water Supply & Irrigation Systems'},
        {'label': 'Sewage Treatment Facilities', 'value': 'Sewage Treatment Facilities'},
        {'label': 'Heating & Air-Conditioning Supply', 'value': 'Heating & Air-Conditioning Supply'},
        {'label': 'Multiline Utilities', 'value': 'Multiline Utilities'},
        {'label': 'Real Estate Rental, Development & Operations (NEC)', 'value': 'Real Estate Rental, Development & Operations (NEC)'},
        {'label': 'Office Real Estate Rental & Development', 'value': 'Office Real Estate Rental & Development'},
        {'label': 'Retail Real Estate Rental & Development', 'value': 'Retail Real Estate Rental & Development'},
        {'label': 'Industrial Real Estate Rental & Development', 'value': 'Industrial Real Estate Rental & Development'},
        {'label': 'Residential Real Estate Rental & Development', 'value': 'Residential Real Estate Rental & Development'},
        {'label': 'Real Estate Services (NEC)', 'value': 'Real Estate Services (NEC)'},
        {'label': 'Office Real Estate Services', 'value': 'Office Real Estate Services'},
        {'label': 'Retail Real Estate Services', 'value': 'Retail Real Estate Services'},
        {'label': 'Industrial Real Estate Services', 'value': 'Industrial Real Estate Services'},
        {'label': 'Residential Real Estate Services', 'value': 'Residential Real Estate Services'},
        {'label': 'Diversified REITs', 'value': 'Diversified REITs'},
        {'label': 'Commercial REITs (NEC)', 'value': 'Commercial REITs (NEC)'},
        {'label': 'Office REITs', 'value': 'Office REITs'},
        {'label': 'Retail REITs', 'value': 'Retail REITs'},
        {'label': 'Industrial REITs', 'value': 'Industrial REITs'},
        {'label': 'Residential REITs', 'value': 'Residential REITs'},
        {'label': 'Specialized REITs (NEC)', 'value': 'Specialized REITs (NEC)'},
        {'label': 'Healthcare REITs', 'value': 'Healthcare REITs'},
        {'label': 'Hospitality REITs', 'value': 'Hospitality REITs'},
        {'label': 'Self-Storage REITs', 'value': 'Self-Storage REITs'},
        {'label': 'Timber REITs', 'value': 'Timber REITs'},
        {'label': 'Mortgage REITs', 'value': 'Mortgage REITs'},
        {'label': 'Islamic REITs', 'value': 'Islamic REITs'},
        {'label': 'Religious Organizations', 'value': 'Religious Organizations'},
        {'label': 'Civic & Social Organizations', 'value': 'Civic & Social Organizations'},
        {'label': 'Environmental Organizations', 'value': 'Environmental Organizations'},
        {'label': 'Charity Organizations', 'value': 'Charity Organizations'},
        {'label': 'Professional Organizations (NEC)', 'value': 'Professional Organizations (NEC)'},
        {'label': 'Business, Professional & Labor Organizations', 'value': 'Business, Professional & Labor Organizations'},
        {'label': 'Political Organizations', 'value': 'Political Organizations'},
        {'label': 'Non-Governmental Organizations (NGOs)', 'value': 'Non-Governmental Organizations (NGOs)'},
        {'label': 'Government & Government Finance (NEC)', 'value': 'Government & Government Finance (NEC)'},
        {'label': 'Public Finance Activities', 'value': 'Public Finance Activities'},
        {'label': 'Legal & Safety Public Services (NEC)', 'value': 'Legal & Safety Public Services (NEC)'},
        {'label': 'Police, Justice & Legal Counsel', 'value': 'Police, Justice & Legal Counsel'},
        {'label': 'Fire', 'value': 'Fire'},
        {'label': 'Government Administration Activities', 'value': 'Government Administration Activities'},
        {'label': 'National Security & International Affairs', 'value': 'National Security & International Affairs'},
        {'label': 'Miscellaneous Educational Service Providers', 'value': 'Miscellaneous Educational Service Providers'},
        {'label': 'School, College & University (NEC)', 'value': 'School, College & University (NEC)'},
        {'label': 'Nursery & Pre-Schools', 'value': 'Nursery & Pre-Schools'},
        {'label': 'Elementary & Primary Schools', 'value': 'Elementary & Primary Schools'},
        {'label': 'Colleges & Secondary Schools', 'value': 'Colleges & Secondary Schools'},
        {'label': 'Universities', 'value': 'Universities'},
        {'label': 'School Districts', 'value': 'School Districts'},
        {'label': 'Professional & Business Education', 'value': 'Professional & Business Education'},
      ]
    };
  }

  changeName(event) {
      this.setCompanyState('name', event.target.value)
  }

  changeWebsite(event) {
    this.setCompanyState('website', event.target.value)
  }

  changeHqLocation(event) {
    this.setCompanyState('hq_location', event)
  }

  changeSectors(event) {
    let value = [];
    if(event) {
      value = event
    }
    this.setCompanyState('sectors', value);
  }


  setCompanyState(key, value) {
      let company = this.state.company
      company[key] = value
      this.setState({company: company})
  }

  submitForm() {
      this.setState(
          {
              selectedCompany: {},
              loading: true
          }
      )
      this.state.submitCallback(this.state.company, this.props.selected.uuid).then((response) => {
        this.setState({loading: false})
        if(response && !response.errors) {
            this.resetFields()
        }
      })
  }
  resetFields() {
    this.setState({
      company: {
        name: '',
        website: '',
        hq_location: '',
        is_public: false,
        sectors: []
     }
    })
  }
  render() {
    return <div>
        <Form>
            <Row>
                <Col xs={12} md={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>Company Name *</Form.Label>
                        <Form.Control value={this.state.company.name} onChange={this.changeName} type="text" placeholder="eg: Uber" />
                    </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>Website</Form.Label>
                        <Form.Control value={this.state.company.website} onChange={this.changeWebsite} type="text" placeholder="eg: https://www.uber.com" />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>HQ Location</Form.Label>
                        <GooglePlacesAutocomplete apiKey="AIzaSyCmKqTmIIMG_z4w8XJs306g4kdEfS2WCSA" selectProps={{value: this.state.company.hq_location, onChange: this.changeHqLocation}} />
                    </Form.Group>
                </Col>
                <Col xs={12} md={4}>
                <Form.Group className="mb-3">
                        <Form.Label>Sectors</Form.Label>
                        <CreatableSelect isMulti={true} value={this.state.company.sectors} onChange={this.changeSectors} options={this.state.companySectors} />
                    </Form.Group>
                </Col>
                <Col xs={12} md={2}>
                    <Form.Group className="mb-3">
                        <Form.Label>Public company?</Form.Label>
                        <Form.Check checked={this.state.company.is_public ? this.state.company.is_public : false} onChange={(event) => this.setCompanyState('is_public', event.target.checked)} />
                        
                    </Form.Group>
                </Col>
            </Row>
            <hr></hr>
            <Row><Col><Button disabled={this.state.loading} variant='dark' onClick={this.submitForm}>{this.state.loading ? <Loader type="ThreeDots" height={'100%'} color="#FFFFFF" /> : this.props.selected.uuid ? 'Update' : 'Create'}</Button></Col></Row>
        </Form>
    </div>;
  }
  

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.selected.uuid !== prevProps.selected.uuid) {
      this.setState({
          company: {
            name: this.props.selected.name || '',
            website: this.props.selected.website || '',
            hq_location: this.props.selected.hq_location || '',
            sectors: this.props.selected.sectors || [],
            is_public: this.props.selected.is_public || false
        }
      })
    }
  }
}

export default AddCompany;
