import React from 'react';
import {ListGroup, Badge, Card, Row, Col, Alert} from 'react-bootstrap'
import Loader from "react-loader-spinner";

class DealProfile extends React.Component {
  constructor(props) {
    super();
    this.state = {
        dealUuid: props.companyUuid,
    deal: null,
      loading: true,
    };
  }

  componentDidMount() {
    this.loadDeal();
  }

  loadDeal() {
    fetch(process.env.REACT_APP_API_URL+"/deals/"+this.props.dealUuid, {
        method: 'GET',
        headers: {
          'Content-Type':'application/json','Authorization': 'Bearer '+localStorage.getItem("apiToken")
        },
      }).then(response => response.json())
      .then(data => {
        this.setState({deal: data, loading: false})
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  renderLinkWith(linkedWith) {
    if(!linkedWith) {
        return null
    }

    return <p>Linked with deal : <a rel="noreferrer" target="_blank" href={"/deals/"+linkedWith.deal.uuid}>{linkedWith.deal.nickname}</a></p>
  }
  renderActor(actor, index) {
      if(actor.entity_type === 'person') {
        return <ListGroup.Item>
                <b><a rel="noreferrer" target="_blank" href={"/people/"+actor.person.uuid}>{index+1} - {actor.person.name}</a></b> <Badge bg={'dark'}>Person</Badge>
        </ListGroup.Item> 
      }
    
    return <ListGroup.Item>
            <Row><div><b><a rel="noreferrer" target="_blank" href={"/companies/"+actor.company.uuid}>{index+1} - {actor.company.name}</a></b> <Badge bg={'dark'}>Company</Badge></div></Row>
            <hr/>
            {actor.comment ? <Alert variant="info"><pre>{actor.comment}</pre></Alert> : null}
            <Row>
                
                <ListGroup variant="flush">
                    <ListGroup.Item variant="secondary"><b>People <Badge bg="primary" pill>{actor.persons.length}</Badge></b></ListGroup.Item>
                    {actor.persons.map(person => this.renderPerson(person))}
                </ListGroup>
            </Row>
            <hr/>
            <Row>
                <ListGroup variant="flush">
                    <ListGroup.Item variant="secondary"><b>Advisors <Badge bg="primary" pill>{actor.advisors.length}</Badge></b></ListGroup.Item>
                    {actor.advisors.map(advisor => this.renderAdvisor(advisor))}
                </ListGroup>
            </Row>
            <hr/>
            <Row>
                <ListGroup variant="flush">
                    <ListGroup.Item variant="secondary"><b>Parents <Badge bg="primary" pill>{actor.parents.length}</Badge></b></ListGroup.Item>
                    {actor.parents.map(parent => this.renderParent(parent))}
                </ListGroup>
            </Row>
    </ListGroup.Item>
  }
  renderPerson(person) {
      return <ListGroup.Item action rel="noreferrer" target="_blank" href={"/people/"+person.uuid} variant="secondary">{person.name} - {person.title}</ListGroup.Item>
  }

  renderAdvisor(advisor) {
    return <ListGroup.Item action rel="noreferrer" target="_blank" href={"/companies/"+advisor.company.uuid} variant="secondary">
                {advisor.company.name}
                <br/>
                {advisor.types.map(advisorType => <Badge bg="primary" pill>{advisorType}</Badge>)}
                <ul>
                    {advisor.persons.map(advisorPerson => this.renderAdvisorPerson(advisorPerson))}
                </ul>
        </ListGroup.Item>
  }
  renderAdvisorPerson(advisorPerson) {
    return <li><a target="_blank" rel="noreferrer" href={"/people/"+advisorPerson.uuid}>{advisorPerson.name} ({advisorPerson.title})</a></li>
  }

  renderParent(parent) {
    return <ListGroup.Item action rel="noreferrer" target="_blank" href={"/companies/"+parent.uuid} variant="secondary">{parent.name}</ListGroup.Item>
  }
  render() {

    if(this.state.loading) {
        return <Loader type="ThreeDots" color="#00BFFF" height={80} width={80} />
    }

    return <div>
        <Card>
            <Card.Header>{this.state.deal.deal.nickname}</Card.Header>
            <ListGroup variant="flush">
                <ListGroup.Item>
                    <Row>
                        <div>
                            <Badge bg={'primary'}><b>Date rumoured: </b> {this.state.deal.deal.dateRumoured || 'Unknown'}</Badge>
                            {' '}
                            <Badge bg={'primary'}><b>Date announced: </b> {this.state.deal.deal.dateAnnounced || 'Unknown'}</Badge>
                            {' '}
                            <Badge bg={'primary'}><b>Date completed: </b> {this.state.deal.deal.dateCompleted || 'Unknown'}</Badge>
                        </div>
                    </Row>
                    <Row>
                        <div>
                            <Badge bg={'success'}><b>Total Capital (%sold): </b> {this.state.deal.deal.totalCapital ? this.state.deal.deal.totalCapital : (this.state.deal.deal.totalCapitalBracket ? this.state.deal.deal.totalCapitalBracket.label : 'Unknown') } {this.state.deal.deal.currency} ({this.state.deal.deal.percentSold || 'Unknown'}%)</Badge>
                            {' '}
                            <Badge bg={'success'}><b>Post Deal Val.:</b> {this.state.deal.deal.postDealValuation ? this.state.deal.deal.postDealValuation : 'Unknown'}</Badge>
                            {' '}
                            <Badge bg={'success'}><b>Currency: </b> {this.state.deal.deal.currency ? this.state.deal.deal.currency : 'Unknown'}</Badge>
                            {' '}
                            <Badge bg={'info'}><b>Deal Type: </b> {this.state.deal.deal.dealType}</Badge>
                            {' '}
                            <Badge bg={'info'}><b>Deal Status: </b> {this.state.deal.deal.status ? this.state.deal.deal.status : 'Unknown'}</Badge>
                            {' '}
                            <Badge bg={'info'}><b>Deal Stage: </b> {this.state.deal.deal.stage ? this.state.deal.deal.stage : 'Unknown'}</Badge>
                            {this.renderLinkWith(this.state.deal.deal.linkedWith)}
                        </div>
                    </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                <Row>
                    {this.state.deal.deal.comment ? <Alert variant="info">
                        <h5>Comment</h5>
                        <pre>{this.state.deal.deal.comment}</pre>
                    </Alert>: null}
                </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                    <Row>
                        <Col xs={12} sm={4}>
                            <Card>
                                <Card.Header>Bidder(s)</Card.Header>
                                <ListGroup>
                                    {this.state.deal.lenders.map((lender, index) => this.renderActor(lender, index))}
                                </ListGroup>
                            </Card>
                        </Col>
                        <Col xs={12} sm={4}>
                            <Card>
                                <Card.Header>Target(s)</Card.Header>
                                <ListGroup>
                                {this.state.deal.targets.map((target, index) => this.renderActor(target, index))}
                                </ListGroup>
                            </Card>
                        </Col>
                        <Col xs={12} sm={4}>
                            <Card>
                                <Card.Header>Vendor(s)</Card.Header>
                                <ListGroup>
                                {this.state.deal.vendors.map((vendor, index) => this.renderActor(vendor, index))}
                                </ListGroup>
                            </Card>
                        </Col>
                    </Row>
                </ListGroup.Item>
            </ListGroup>
        </Card>
    </div>;
  }
}

export default DealProfile;
