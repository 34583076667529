import React from 'react';
import {Accordion, ListGroup, Col, Row, Badge, Modal, Button, InputGroup, FormControl, Tabs, Tab, Form, Alert} from 'react-bootstrap'
import './AddDeal.css'
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import DatePicker from "react-datepicker";
import autoBind from 'react-autobind';
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import Loader from 'react-loader-spinner';
import QuickAddActor from './QuickAddActor';
/*
TODO : Update the company select2 to also have persons with a separation
If you pick a company then the list below is as normal, otherwise it hides them
Should also have a "Unknown" option

- We want to initialize properly the people modal
  > On Update we want to load the person and employment
  > On add we want to make sure the first person isn't selected
- Deal type isn't setup properly 

- dateAnnounced 
*/
class AddDeal extends React.Component {
  constructor(props) {
    super();
    autoBind(this);
    this.state = {
      allCompanies: [],
      allPersons: [],
      allPersonsOptions: [],
      allCompaniesOptions: [],
      showPersonModal: false,
      quickAddModalOpen: false,
      quickAddName: null,
      companiesLoading: true,
      personsLoading: true,
      personTarget: null,
      currentLenders: [
        {
          company: null,
          person: null,
          comment: null,
          persons: [],
          parentCompanies: [],
          advisors: []
        }
      ],
      currentTargets: [
        {
          company: null,
          comment: null,
          person: null,
          persons: [],
          parentCompanies: [],
          advisors: []
        }
      ],
      currentVendors: [
        {
          company: null,
          comment: null,
          person: null,
          persons: [],
          parentCompanies: [],
          advisors: []
        }
      ],
      currentPersonEmployments: [
        {'label': <i>Add new employment</i>,'value': 0, 'employment': 0}
      ],
      currentSelectedEmployment: null,
      currentNewEmploymentTitle: null,
      curentNewEmploymentFrom: null,
      currentNewEmploymentTo: null,
      currentSelectedPerson: null,
      selectedNewAdvisorCompany: null,
      loading: false,
      submitCallback: props.submitCallback,
      selectedNewAdvisorTypes: null,
      deal: {
        linkWith: null,
        totalCapital: null,
        totalCapitalBracket: null,
        percentSold: null,
        currency: null,
        dealType: null,
        status: null,
        comment: null,
        nickname: null,
        postDealValuation: null,
        dateAnnounced: null,
        dateCompleted: null,
        dateRumoured: null
      }
    };
  }

  getAdvisorTypes() {
    return [
      {'label': 'Financial Advisor', 'value': 'Financial Advisor'},
      {'label': 'Legal', 'value': 'Legal'},
      {'label': 'Broking', 'value': 'Broking'},
      {'label': 'PR', 'value': 'PR'},
      {'label': 'Debt Advisor', 'value': 'Debt Advisor'},
      {'label': 'Tax Advisor', 'value': 'Tax Advisor'},
      {'label': 'Financial DD', 'value': 'Financial DD'},
      {'label': 'Commercial DD', 'value': 'Commercial DD'},
      {'label': 'Operational DD', 'value': 'Operational DD'},
      {'label': 'Global Coordinator', 'value': 'Global Coordinator'},
      {'label': 'Bookrunner', 'value': 'Bookrunner'}
    ]
  }

  getCurrencies() {
    return [
      {'label': 'USD', 'value': 'USD'},
      {'label': 'EUR', 'value': 'EUR'},
      {'label': 'GBP', 'value': 'GBP'}
    ]
  }

  getDealStatuses() {
    return [
      {'label': 'Started', 'value': 'Started'},
      {'label': 'Cancelled', 'value': 'Cancelled'},
      {'label': 'Rumoured', 'value': 'Rumoured'},
      {'label': 'Unknown', 'value': 'Unknown'}
    ]
  }

  getDealStages() {
    return [
      {'label': 'Pre-Seed', 'value': 'Pre-Seed'},
      {'label': 'Seed', 'value': 'Seed'},
      {'label': 'Series A', 'value': 'Series A'},
      {'label': 'Series B', 'value': 'Series B'},
      {'label': 'Series C', 'value': 'Series C'},
      {'label': 'Series D', 'value': 'Series D'},
      {'label': 'Series E', 'value': 'Series E'},
      {'label': 'Series F', 'value': 'Series F'},
      {'label': 'Series G', 'value': 'Series G'}
    ]
  }
  getValuationBrackets() {
    return [
      {
        'label': 'Micro < $9.99m',
        'options': [
          {
            'label': '$<1m' ,
            'value': {
              'from': 0,
              'to': 1,
              'label': '$<1m'
            }
          },
          {
            'label': '$1-4.99m' ,
            'value': {
              'from': 1,
              'to': 5,
              'label': '$1-4.99m'
            }
          },
          {
            'label': '$5-9.99m' ,
            'value': {
              'from': 5,
              'to': 10,
              'label': '$5-9.99m'
            }
          }
        ]
      },
      {
        'label': 'Small $10-99m',
        'options': [
          {
            'label': '$10-24.9m' ,
            'value': {
              'from': 10,
              'to': 25,
              'label': '$10-24.9m'
            }
          },
          {
            'label': '$25-49.9m' ,
            'value': {
              'from': 25,
              'to': 50,
              'label': '$25-49.9m'
            }
          },
          {
            'label': '$50-74.9m' ,
            'value': {
              'from': 50,
              'to': 75,
              'label': '$50-74.9m'
            }
          },
          {
            'label': '$74.9-99.9m' ,
            'value': {
              'from': 75,
              'to': 100,
              'label': '$10-24.9m'
            }
          },
        ]
      },
      {
        'label': 'Midcap: $100-499m',
        'options': [
          {
            'label': '$100-199m' ,
            'value': {
              'from': 100,
              'to': 200,
              'label': '$100-199m'
            }
          },
          {
            'label': '$200-349m' ,
            'value': {
              'from': 200,
              'to': 350,
              'label': '$200-349m'
            }
          },
          {
            'label': '$350-499m' ,
            'value': {
              'from': 350,
              'to': 500,
              'label': '$350-499m'
            }
          }
        ]
      },
      {
        'label': 'Large Cap: $501-999m',
        'options': [
          {
            'label': '$500-749m' ,
            'value': {
              'from': 500,
              'to': 750,
              'label': '$500-749m'
            }
          },
          {
            'label': '$749-999m' ,
            'value': {
              'from': 750,
              'to': 1000,
              'label': '$749-999m'
            }
          }
        ]
      },
      {
        'label': 'Mega Deal: >$1000m',
        'options': [
          {
            'label': '$1bn-2bn' ,
            'value': {
              'from': 1000,
              'to': 2000,
              'label': '$1bn-2bn'
            }
          },
          {
            'label': '$2bn-4bn' ,
            'value': {
              'from': 2000,
              'to': 4000,
              'label': '$2bn-4bn'
            }
          },
          {
            'label': '$4bn-6bn' ,
            'value': {
              'from': 4000,
              'to': 6000,
              'label': '$4bn-6bn'
            }
          },
          {
            'label': '$6-9.9bn' ,
            'value': {
              'from': 6000,
              'to': 10000,
              'label': '$6-9.9bn'
            }
          },
          {
            'label': '$10-15.9bn' ,
            'value': {
              'from': 10000,
              'to': 16000,
              'label': '$10-15.9bn'
            }
          },
          {
            'label': '$16-25bn' ,
            'value': {
              'from': 16000,
              'to': 25000,
              'label': '$16-25bn'
            }
          },
          {
            'label': '$25-40bn' ,
            'value': {
              'from': 25000,
              'to': 40000,
              'label': '$25-40bn'
            }
          },
          {
            'label': '$40-60bn' ,
            'value': {
              'from': 40000,
              'to': 60000,
              'label': '$40-60bn'
            }
          },
          {
            'label': '$60bn+' ,
            'value': {
              'from': 60000,
              'to': Infinity,
              'label': '$60bn+'
            }
          }
        ]
      }
    ]
  }

  valueToBracket(value, currency) {
    let currentBrackets = this.getValuationBrackets()
    let usdM = this.convertValueToUSD(value, currency) / 1000000
    for (let i = 0; i < currentBrackets.length; i++) {
      const currentBracket = currentBrackets[i];
      for (let j = 0; j < currentBracket['options'].length; j++) {
        const bracket = currentBracket['options'][j];
        
        if(usdM < bracket.value.to && usdM >= bracket.value.from) {
          return bracket

        }
      }
    }
    return currentBrackets[0]['options'][0]
  }

  convertValueToUSD(value, from) {
    if(from === 'GBP') {
      return value * 1.34
    }

    if (from === 'EUR') {
      return value * 1.11
    }
    return value
  } 
  getDealTypes() {
    return [
      {
        'label': 'M&A',
        'options': [
          {'label': 'Growth Equity / Minority Stake', 'value': 'Growth Equity / Minority Stake'},
          {'label': 'Acquisition', 'value': 'Acquisition'},
          {'label': 'LBO', 'value': 'LBO'},
          {'label': 'Reverse Takeover', 'value': 'Reverse Takeover'},
          {'label': 'Carve-out', 'value': 'Carve-out'},
          {'label': 'Merger', 'value': 'Merger'},
        ]
      },
      {
        'label': 'Equity',
        'options': [
          {'label': 'IPO', 'value': 'IPO'},
          {'label': 'Rights/Other Issue', 'value': 'Rights/Other Issue'},
          {'label': 'Private placement', 'value': 'Private placement'}
        ]
      },
      {
        'label': 'Debt',
        'options': [
          {'label': 'Venture Debt', 'value': 'Venture Debt'},
          {'label': 'Promoter Finance / Share backed Finance', 'value': 'Promoter Finance / Share backed Finance'},
          {'label': 'Project Financing', 'value': 'Project Financing'},
          {'label': 'Asset Financing', 'value': 'Asset Financing'},
          {'label': 'MBO/LBO financing', 'value': 'MBO/LBO financing'},
          {'label': 'Corporate Acquisition Financing', 'value': 'Corporate Acquisition Financing'},
          {'label': 'Dividend Recapitalisation', 'value': 'Dividend Recapitalisation'},
          {'label': 'ECA/DFI Financing', 'value': 'ECA/DFI Financing'},
          {'label': 'Other Financing', 'value': 'Other Financing'}
        ]
      }
    ]
  }

  getLinkedDeals() {
    return this.props.deals.map(deal => {return {value: deal.deal.uuid, deal: deal.deal, label: deal.deal.nickname}})
  }

  loadCompanies() {
    fetch(process.env.REACT_APP_API_URL+"/company", {headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+localStorage.getItem("apiToken")
    }}).then(response => response.json())
    .then(data => {
      let allCompanies = data.map(company => {return {label: company.name, value: company.uuid, company: company}})
      allCompanies.unshift({label: <Badge bg="danger">Unknown company</Badge>, value: null, company: {is_unknown: true}})
      //TODO Add at the beginning
      this.setState({
        allCompanies: data,
        companiesLoading: false,
        allCompaniesOptions: allCompanies
      })
    })
    .catch((error) => {
      this.setState({
        companiesLoading: false
      })
      console.error('Error:', error);
    });
  }


  loadPersons() {
    fetch(process.env.REACT_APP_API_URL+"/person", {headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+localStorage.getItem("apiToken")
    }}).then(response => response.json())
    .then(data => {
      this.setState({
        allPersons: data,
        personsLoading: false,
        allPersonsOptions: data.map(person => {return {label: person.name, value: person.uuid, person: person}})
      })
    })
    .catch((error) => {
      this.setState({
        personsLoading: false 
      })
      console.error('Error:', error);
    });
  }
  renderValidBadge(section) {
    let isValid = false;
    if(section === 'bidder') {
      isValid = this.state.currentLenders.length
    }

    if (section === 'target') {
      isValid = this.state.currentTargets.length
    }

    if (section === 'vendor') {
      isValid = this.state.currentVendors.length
    }

    return this.validBadge(isValid)

  }

  validBadge(valid) {
    if(valid) {
      return <b className="success-badge">✓</b>
    } else {
      return <b className="danger-badge">✗</b>
    }
  }

  hasValidDate() {
    if(this.state.deal.dateRumoured || this.state.deal.dateAnnounced || this.state.deal.dateCompleted) {
      return true
    }
    return false
  }
  canAddDeal() {
    //TODO : Check that the first item has a companyt
    if(this.state.currentLenders.length && this.state.currentTargets.length && this.hasValidDate()) {
      return true
    }
    return false
  }

  addDeal() {
    let dealJson = {
      lenders: this.state.currentLenders,
      targets: this.state.currentTargets,
      vendors: this.state.currentVendors,
      deal: this.state.deal
    }
    this.setState({
      loading: true
    })
    this.props.submitCallback(dealJson).then(data => {
      this.setState({loading: false})
      this.resetFields()
    })
  }

  updateDeal() {
    let dealJson = {
      lenders: this.state.currentLenders,
      targets: this.state.currentTargets,
      vendors: this.state.currentVendors,
      deal: this.state.deal
    }
    this.setState({
      loading: true
    })
    this.props.updateCallback(dealJson, this.props.selected.deal.uuid).then(data => {
      this.setState({loading: false})
      if(data && !data.errors) {
        this.resetFields()
      }
    })
  }

  resetFields() {
    this.setState({
      showPersonModal: false,
      personTarget: null,
      currentLenders: [
        {
          company: null,
          person: null,
          comment: null,
          persons: [],
          parentCompanies: [],
          advisors: []
        }
      ],
      currentTargets: [
        {
          company: null,
          comment: null,
          person: null,
          persons: [],
          parentCompanies: [],
          advisors: []
        }
      ],
      currentVendors: [
        {
          company: null,
          comment: null,
          person: null,
          persons: [],
          parentCompanies: [],
          advisors: []
        }
      ],
      currentPersonEmployments: [
        {'label': <i>Add new employment</i>,'value': 0, 'employment': 0}
      ],
      currentSelectedEmployment: null,
      currentPersonEmployment: null,
      currentNewEmploymentTitle: null,
      curentNewEmploymentFrom: null,
      currentNewEmploymentTo: null,
      currentSelectedPerson: null,
      selectedNewAdvisorCompany: null,
      selectedNewAdvisorTypes: null,
      deal: {
        linkWith: null,
        totalCapital: null,
        percentSold: null,
        currency: null,
        dealType: null,
        status: null,
        nickname: null,
        postDealValuation: null,
        dateAnnounced: null,
        dateCompleted: null,
        dateRumoured: null
      }
    }
    );
    this.dataSaved()
  }

  renderGroupDealType(group) {
    return <div>
    <span>{group.label}</span>
  </div>
  }
  handleChangeDealData(data, fieldKey) {
    this.hasUnsavedData()
    let dealData = this.state.deal
    if(data && data.target) {
      dealData[fieldKey] = data.target.value
    } else {
      dealData[fieldKey] = data
    }
    
    this.setState({
      deal: dealData
    })
  }

  createActor() {
    return {
      company: null,
      comment: null,
      person: null,
      persons: [],
      parentCompanies: [],
      advisors: []
    }
  }


  handleChangeDealPostVal(event) {
    const currency = this.state.deal.currency ? this.state.deal.currency.value : 'USD'
    let bracket = null
    if(event.target.value) {
      bracket = this.valueToBracket(event.target.value, currency)
    }
    this.handleChangeDealData(bracket, 'totalCapitalBracket')
    this.handleChangeDealData(event, 'postDealValuation')
  }

  handleChangeDealCurrency(value) {
    let bracket = null
    if(this.state.deal.totalCapital) {
      bracket = this.valueToBracket(this.state.deal.totalCapital, value.value)
    }
    this.setState({
      totalCapitalBracket: bracket
    })
    this.handleChangeDealData(bracket, 'totalCapitalBracket')
    this.handleChangeDealData(value, 'currency')
  }

  companyAdded(company, uuid) {
    this.setState({
      quickAddModalOpen: false
    })

    let allCompanies = this.state.allCompanies
    allCompanies.push(company)
    let allCompaniesOptions = this.state.allCompaniesOptions
    allCompaniesOptions.push({label: company.name, value: company.uuid, company: company})
    this.handleChangeCompany({company: company}, this.state.quickAddStage, this.state.quickAddIndex)
    return this.setState({
      allCompanies: allCompanies,
      companiesLoading: false,
      quickAddIndex: null,
      quickAddStage: null,
      quickAddModalOpen: false,
      allCompaniesOptions: allCompaniesOptions
    })
  }
  render() {
    return <div>
            {this.renderPersonModal()}
            <QuickAddActor name={this.state.quickAddName} handleClose={() => this.setState({quickAddModalOpen: false})} open={this.state.quickAddModalOpen} submitCallback={(company) => this.companyAdded(company)} />
    <Accordion defaultActiveKey="0">
    <Accordion.Item eventKey="0">
      <Accordion.Header>1- Capital Provider {this.renderValidBadge('bidder')}</Accordion.Header>
      <Accordion.Body>{this.renderActors(this.state.currentLenders, 'bidder')}</Accordion.Body>
    </Accordion.Item>
    <Accordion.Item eventKey="1">
      <Accordion.Header>2 - Target {this.renderValidBadge('target')}</Accordion.Header>
      <Accordion.Body>{this.renderActors(this.state.currentTargets, 'target')}</Accordion.Body>
    </Accordion.Item>
    <Accordion.Item eventKey="2">
      <Accordion.Header>3 - Vendor </Accordion.Header>
      <Accordion.Body>{this.renderActors(this.state.currentVendors, 'vendor')}</Accordion.Body>
    </Accordion.Item>
    <Accordion.Item eventKey="3">
      <Accordion.Header>4 - Deal information {this.validBadge(this.hasValidDate() && this.state.deal.dealType)}</Accordion.Header>
      <Accordion.Body>
      <Row>
          <h5>Main informations</h5>
            <Col xs={12} sm={2}>
                <Form.Group className="mb-3">
                    <Form.Label>Deal type *</Form.Label>
                    <Select
                      options={this.getDealTypes()}
                      value={this.state.deal.dealType}
                      onChange={(event) => this.handleChangeDealData(event, 'dealType')}
                      formatGroupLabel={this.renderGroupDealType}
                    />
                </Form.Group>
            </Col>
            <Col xs={12} sm={2}>
                <Form.Group className="mb-3">
                    <Form.Label>Deal status</Form.Label>
                    <Select
                      options={this.getDealStatuses()}
                      value={this.state.deal.status}
                      onChange={(event) => this.handleChangeDealData(event, 'status')}
                      formatGroupLabel={this.renderGroupDealType}
                    />
                </Form.Group>
            </Col>
            <Col xs={12} sm={2}>
                <Form.Group className="mb-3">
                    <Form.Label>Deal stage</Form.Label>
                    <Select
                      options={this.getDealStages()}
                      value={this.state.deal.stage}
                      onChange={(event) => this.handleChangeDealData(event, 'stage')}
                      formatGroupLabel={this.renderGroupDealType}
                    />
                </Form.Group>
            </Col>
            <Col xs={12} sm={4}>
                <Form.Group className="mb-3">
                    <Form.Label>Link with deal</Form.Label>
                    <Select
                      options={this.getLinkedDeals()}
                      value={this.state.deal.linkWith}
                      onChange={(event) => this.handleChangeDealData(event, 'linkWith')}
                      isClearable={true}
                      formatGroupLabel={this.renderGroupDealType}
                    />
                </Form.Group>
            </Col>
        </Row>
      <Row>
        <h5>Financials</h5>
                <Col xs={4} sm={3}>
                  <Form.Group className="mb-3">
                      <Form.Label>Total capital</Form.Label>
                      <Form.Control value={this.state.deal.totalCapital || ''} onChange={(event) => this.handleChangeDealData(event, 'totalCapital')} type="number"/>
                  </Form.Group>
                </Col>
                <Col xs={4} sm={3}>
                  <Form.Group className="mb-3">
                      <Form.Label>Size Bracket</Form.Label>
                      <Select
                        options={this.getValuationBrackets()}
                        isClearable={true}
                        isDisabled={this.state.deal.postDealValuation}
                        value={this.state.deal.totalCapitalBracket}
                        onChange={(event) => this.handleChangeDealData(event, 'totalCapitalBracket')}
                      />
                  </Form.Group>
                </Col>
                <Col xs={4} sm={3}>
                  <Form.Group className="mb-3">
                      <Form.Label>Currency</Form.Label>
                      <Select
                        options={this.getCurrencies()}
                        isClearable={false}
                        value={this.state.deal.currency}
                        onChange={(event) => this.handleChangeDealCurrency(event)}
                      />
                  </Form.Group>
                </Col>
            <Col xs={6} sm={1}>
                <Form.Group className="mb-3">
                    <Form.Label>% Acquired</Form.Label>
                    <Form.Control value={this.state.deal.percentSold || ''} min={0} max={100} onChange={(event) => this.handleChangeDealData(event, 'percentSold')} type="number"/>
                </Form.Group>
            </Col>
            <Col xs={6} sm={2}>
                <Form.Group className="mb-3">
                    <Form.Label>Post-deal val.</Form.Label>
                    <Form.Control value={this.state.deal.postDealValuation || ''} onChange={(event) => this.handleChangeDealPostVal(event)} type="number"/>
                </Form.Group>
            </Col>

        </Row>
        <Row>
          <h5>Dates*</h5>
          <Col xs={2}>
                <Form.Group className="mb-4">
                    <Form.Label>Rumoured</Form.Label>
                    <DatePicker selected={this.state.deal.dateRumoured} onChange={(date) => this.handleChangeDealData(date, 'dateRumoured')} />
                </Form.Group>
            </Col>
            <Col xs={2}>
                <Form.Group className="mb-3">
                    <Form.Label>Announced</Form.Label>
                    <DatePicker selected={this.state.deal.dateAnnounced} onChange={(date) => this.handleChangeDealData(date, 'dateAnnounced')} />
                </Form.Group>
            </Col>
            <Col xs={2}>
                <Form.Group className="mb-3">
                    <Form.Label>Completed</Form.Label>
                    <DatePicker selected={this.state.deal.dateCompleted} onChange={(date) => this.handleChangeDealData(date, 'dateCompleted')} />
                </Form.Group>
            </Col>
            <Col xs={4}>
              {this.hasValidDate() ? null : <Alert variant='warning'>At least one date is mandatory</Alert>}
            </Col>
        </Row>
        <Row>
          <h5>Misc informations</h5>
          <Col xs={12} sm={4}>
                <Form.Group className="mb-3">
                    <Form.Label>Deal nickname</Form.Label>
                    <Form.Control value={this.state.deal.nickname || ''} onChange={(event) => this.handleChangeDealData(event, 'nickname')} type="text"/>
                </Form.Group>
          </Col>
          <Col xs={12} sm={8}>
                <Form.Group className="mb-3">
                    <Form.Label>Comment</Form.Label>
                    <Form.Control value={this.state.deal.comment || ''} type="text" onChange={(value) => this.handleChangeDealData(value, 'comment')} />
                </Form.Group>
          </Col>
        </Row>
      </Accordion.Body>
    </Accordion.Item>
  </Accordion>
    <Row>
      <Col xs={3}>
        <Button onClick={this.props.selected.deal ? this.updateDeal : this.addDeal} disabled={!this.canAddDeal() || this.state.loading}>
          {this.state.loading ? <Loader type="ThreeDots" height={'100%'} color="#FFFFFF" />  : this.props.selected.deal ? 'Update deal' : 'Add deal'}
        </Button>
      </Col>
    </Row>
  </div>
  }

  handleClosePersonModal() {
    this.setState({
      showPersonModal: false,
      personTarget: null,
      currentPersonEmployment: null,
      currentSelectedPerson: null,
      currentNewEmploymentTitle: null,
      currentNewEmploymentFrom: null,
      currentNewEmploymentTo: null
    })
  }

  updateCurrentEmployementWithSelectedPerson(personUuid, currentEmployment, personTarget) {
    const selectedPerson = this.state.allPersonsOptions.find((option) => option.value === personUuid)

    let currentPersonEmployment = JSON.parse(JSON.stringify(currentEmployment))
    // We need to get the currently selected company to load the right employment if we're not just editing the current
    
    const loadedStage = this.getLoadedStage(personTarget)
    let selectedCompanyUuid = loadedStage.company?.uuid;
    if(personTarget.advisor_index) {
      selectedCompanyUuid = loadedStage.advisors[personTarget.advisor_index].company?.uuid
    }

    if(!currentPersonEmployment && selectedCompanyUuid) {
      currentPersonEmployment = selectedPerson.person.employments.find(empl => empl.company_uuid === selectedCompanyUuid)
    }
    
    if (currentPersonEmployment?.to) {
      currentPersonEmployment.to = new Date(currentPersonEmployment.to)
    }
    if (currentPersonEmployment?.from) {
      currentPersonEmployment.from = new Date(currentPersonEmployment.from)
    }

    if (! currentPersonEmployment) {
     currentPersonEmployment = {
      'title': '',
      'from': '',
      'to': ''
     }
    }
    this.setState({
      currentPersonEmployment: currentPersonEmployment
    })
    return selectedPerson
  }

  getLoadedStage(personTarget) {
    if(personTarget.stage === 'bidder') {
      return this.state.currentLenders[personTarget.index]
    }

    if (personTarget.stage === 'target') {
      return this.state.currentTargets[personTarget.index]
    }

    if (personTarget.stage === 'vendor') {
      return this.state.currentVendors[personTarget.index]
    }
    return null
  }
  handleShowPersonModal(personTarget) {
    //Load employments
    if(personTarget.personIndex !== undefined) {
      let selectedPerson = null
      let loadedPerson = null
      const loadedStage = this.getLoadedStage(personTarget)
      if(personTarget.advisor_index !== undefined) {
        loadedPerson = loadedStage.advisors[personTarget.advisor_index].persons[personTarget.personIndex]
      } else {
        loadedPerson = loadedStage.persons[personTarget.personIndex]
      }
      if (loadedPerson) {
        selectedPerson = this.updateCurrentEmployementWithSelectedPerson(loadedPerson.uuid, loadedPerson.employment, personTarget)
      }
      this.setState({
        currentSelectedPerson: selectedPerson
      })
    }

    this.setState({
      showPersonModal: true,
      personTarget: personTarget
    })
  }

  deleteAdvisor(toDelete) {
    if(toDelete.stage === 'bidder') {
      let currentLenders = this.state.currentLenders
      currentLenders[toDelete.index].advisors.splice(toDelete.advisor_index, 1)
      this.setState({currentLenders: currentLenders})
    }

    if (toDelete.stage === 'target') {
      let currentTargets = this.state.currentTargets
      currentTargets[toDelete.index].advisors.splice(toDelete.advisor_index, 1)
      this.setState({currentTargets: currentTargets})
    }

    if (toDelete.stage === 'vendor') {
      let currentVendors = this.state.currentVendors
      currentVendors[toDelete.index].advisors.splice(toDelete.advisor_index, 1)
      this.setState({currentVendors: currentVendors})
    }
  }

  handleChangePerson(event) {
    const selectedPerson = this.state.allPersonsOptions.find((person) => person.person.uuid === event.person.uuid)
    this.updateCurrentEmployementWithSelectedPerson(event.person.uuid, null, this.state.personTarget)
    this.setState({
      currentSelectedPerson: selectedPerson
    })
  }

  mapEmployment(employment) {
    return {
      'label': <div>{employment.title} <i>{employment.from} - {employment.to ? employment.to : 'Present'}</i></div>,
      'value': employment.uuid,
      'employment': employment
    }
  }

  handleChangeNewAdvisor(event) {
    let value = event ? event.company : null;
    this.hasUnsavedData()
    this.setState({
      selectedNewAdvisorCompany: value
    })
  }

  handleChangeNewAdvisorType(event) {
    this.hasUnsavedData()
  
    this.setState({
      selectedNewAdvisorTypes: event.map((event) => event.value)
    });
  }

  addNewAdvisor(stage, index) {
    if(stage === 'bidder') {
      let currentLenders = this.state.currentLenders;
      currentLenders[index] = this.addAdvisorToCompanyObj(currentLenders[index])
      this.setState({
        currentLenders: currentLenders
      })
    }
    if(stage === 'target') {
      let currentTargets = this.state.currentTargets;
      currentTargets[index] = this.addAdvisorToCompanyObj(currentTargets[index])
      this.setState({
        currentTargets: currentTargets
      })
    }
    if(stage === 'vendor') {
      let currentVendors = this.state.currentVendors;
      currentVendors[index] = this.addAdvisorToCompanyObj(currentVendors[index])
      this.setState({
        currentVendors: currentVendors
      })
    }
  }
  addAdvisorToCompanyObj(companyObj) {
    companyObj.advisors.push({'company': this.state.selectedNewAdvisorCompany, 'persons': [], 'types': this.state.selectedNewAdvisorTypes})
    this.setState({
      selectedNewAdvisorCompany: null,
      selectedNewAdvisorTypes: null
    })
    return companyObj
  }
  
  handleEmploymentTitleChange(event) {
    let currentPersonEmployment = this.state.currentPersonEmployment || {'title': '', 'from': '', 'to': ''}
    currentPersonEmployment.title = event.target.value
    this.setState({
      currentPersonEmployment: currentPersonEmployment
    })
  }

  handleChangeEmploymentTo(date) {
    let currentPersonEmployment = this.state.currentPersonEmployment || {'title': '', 'from': '', 'to': ''}
    currentPersonEmployment.to = date
    this.setState({
      currentPersonEmployment: currentPersonEmployment
    })
  }
  handleChangeEmploymentFrom(date) {
    let currentPersonEmployment = this.state.currentPersonEmployment || {'title': '', 'from': '', 'to': ''}
    currentPersonEmployment.from = date
    this.setState({
      currentPersonEmployment: currentPersonEmployment
    })
  }

  renderEmploymentEdition() {
    return <Row>
      <InputGroup className="mb-3">
        <InputGroup.Text id="basic-addon2">Title</InputGroup.Text>
        <FormControl
          placeholder="CEO"
          aria-label="CEO"
          onChange={this.handleEmploymentTitleChange}
          value={this.state.currentPersonEmployment?.title}
          aria-describedby="basic-addon2"
        />
      </InputGroup>
      <Row>
        <Col xs={12} sm={6}>
          <InputGroup className="mb-3">
          <InputGroup.Text id="basic-addon3">From</InputGroup.Text>
          <DatePicker aria-describedby="basic-addon4" 
          selected={this.state.currentPersonEmployment?.from} 
          showMonthYearPicker
          onChange={(date) => this.handleChangeEmploymentFrom(date)} />
        </InputGroup>
        </Col>
        <Col xs={12} sm={6}>
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon4">To</InputGroup.Text>
            <DatePicker aria-describedby="basic-addon4" 
            selected={this.state.currentPersonEmployment?.to}
            showMonthYearPicker
            onChange={(date) => this.handleChangeEmploymentTo(date)} 
            />
          </InputGroup>
        </Col>
      </Row>
      </Row>
  }

  renderPersonModal() {
    return <Modal show={this.state.showPersonModal} onHide={this.handleClosePersonModal}>
    <Modal.Header closeButton>
      <Modal.Title>{this.state.personTarget?.personIndex !== undefined ? 'Update': 'Add'} person</Modal.Title>
    </Modal.Header>
  
    <Modal.Body>
      <Row><b>Person</b></Row>
      <Row><CreatableSelect value={this.state.currentSelectedPerson} onCreateOption={this.createPerson} disabled={this.state.personsLoading} onChange={this.handleChangePerson} options={this.state.allPersonsOptions} /></Row>
      <Row><b>Employment</b></Row>
      <Row>{this.renderEmploymentEdition()}</Row>
    </Modal.Body>
  
    <Modal.Footer>
      <Button variant="secondary" onClick={this.handleClosePersonModal}>Close</Button>
      <Button disabled={!this.state.currentSelectedPerson} onClick={this.processPerson} variant="primary">{this.state.personTarget?.personIndex !== undefined ? 'Update': 'Add'}</Button>
    </Modal.Footer>
  </Modal>
  }

  getSelectedPersonAndEmployment() {
      let person = this.state.currentSelectedPerson.person
      person.employment = {
        'title': this.state.currentPersonEmployment.title,
        'from': this.state.currentPersonEmployment.from.toDateString(),
        'to': this.state.currentPersonEmployment.to ? this.state.currentPersonEmployment.to.toDateString() : 'Present'
      }
      if(this.state.currentPersonEmployment.uuid) {
        person.employment.uuid = this.state.currentPersonEmployment.uuid
      }
      return person
}

addSelectedPersonBasedOnTarget(currentCompanyObj, index) {
  let person = this.getSelectedPersonAndEmployment()
  let currentTarget = this.state.personTarget
  let personArray = []
  if(currentTarget.advisor_index !== undefined) {        
    personArray = currentCompanyObj[index].advisors[currentTarget.advisor_index].persons
  } else {
    personArray = currentCompanyObj[index].persons
  }

  if(currentTarget.personIndex !== undefined) {
    personArray.splice(currentTarget.personIndex, 1, person)
  } else {
    personArray.push(person)
  }
  return currentCompanyObj
}

  processPerson() {
    let currentTarget = this.state.personTarget

    if(currentTarget.stage === 'bidder') {
      this.setState({
        currentLenders: this.addSelectedPersonBasedOnTarget(this.state.currentLenders, currentTarget.index)
      })
    }

    if (currentTarget.stage === 'target') {
      this.setState({
        currentTargets: this.addSelectedPersonBasedOnTarget(this.state.currentTargets, currentTarget.index)
      })
    }

    if (currentTarget.stage === 'vendor') {
      this.setState({
        currentVendors: this.addSelectedPersonBasedOnTarget(this.state.currentVendors, currentTarget.index)
      })
    }
    this.handleClosePersonModal()
  }

  handleChangeCompany(event, stage, index) {
    let value = null;
    let key = 'company'
    let toDel = 'person'
    if(event) {
      value = event.company

      if(!value) {
        value = event.person
        key = 'person'
        toDel = 'company'
      }
    }


    this.setKeyForCompanyAtStage(stage, key, value, index)
    this.setKeyForCompanyAtStage(stage, toDel, null, index)
    this.setKeyForCompanyAtStage(stage, 'entity_type', key, index)
  }

  handleChangeCompanyComment(event, stage, index) {
    this.setKeyForCompanyAtStage(stage, 'comment', event.target.value, index)
  }

  setKeyForCompanyAtStage(stage, key, value, index) {
    this.hasUnsavedData()
    if(stage === 'bidder') {
      let currentLenders = this.state.currentLenders;
      currentLenders[index][key] = value
      this.setState({
        currentLenders: currentLenders
      })
    }
    if(stage === 'target') {
      let currentTargets = this.state.currentTargets;
      currentTargets[index][key] = value
      this.setState({
        currentTargets: currentTargets
      })
    }
    if(stage === 'vendor') {
      let currentVendors = this.state.currentVendors;
      currentVendors[index][key] = value
      this.setState({
        currentVendors: currentVendors
      })
    }
  }

  handleChangeParentCompany(events, stage, index) {
    let value = null;
    if(events) {
      value = events.map(event => event.company)
    }
    this.setKeyForCompanyAtStage(stage, 'parentCompanies', value, index)
  }

  renderPersonRow(person, showPersonArg) {

    return <ListGroup.Item onClick={() => this.handleShowPersonModal(showPersonArg)} action>{person.name} <Badge bg="primary">{person.employment.title}</Badge> <Badge bg="secondary">{person.employment.from} - {person.employment.to ? person.employment.to : 'Present'}</Badge></ListGroup.Item>
  }

  renderAdvisor(advisor, stage, advisor_index, index) {
    return <ListGroup.Item>
        <b>{advisor.company.name}</b>
        <p>{advisor.types.map((advisorType => <Badge bg="info">{advisorType}</Badge>))}</p>
        <ListGroup>
          {advisor.persons.map((person, personIndex) => this.renderPersonRow(person, {'stage': stage, 'advisor_index': advisor_index, 'index': index, 'personIndex': personIndex}))}
          <ListGroup.Item  onClick={() => this.handleShowPersonModal({'stage': stage, 'advisor_index': advisor_index, 'index': index})} className="addbutton" action variant="dark"><Row className="justify-content-center"><Col><b>Add person to advisor</b></Col></Row></ListGroup.Item>
          <ListGroup.Item  onClick={() => this.deleteAdvisor({'stage': stage, 'advisor_index': advisor_index, 'index': index})} className="addbutton" action><Row className="justify-content-center"><Col><b>Delete advisor</b></Col></Row></ListGroup.Item>
        </ListGroup>
    </ListGroup.Item>
  }

  createCompany(companyName, stage, index) {
    return this.setState({
      quickAddModalOpen: true,
      quickAddName: companyName,
      quickAddStage: stage,
      quickAddIndex: index
    })
  }

  createPerson(personName) {
    fetch(process.env.REACT_APP_API_URL+"/person", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem("apiToken")
      },
      body: JSON.stringify({
        name: personName,
        location: 'Unknown'
      }),
    }).then(response => response.json())
    .then(data => {

      let allPersons = this.state.allPersons
      allPersons.push(data)
      let allPersonsOptions = this.state.allPersonsOptions
      allPersonsOptions.push({label: data.name, value: data.uuid, person: data})
      this.setState({
        allPersons: allPersons,
        personsLoading: false,
        allPersonsOptions: allPersonsOptions
      })
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  }

  getTabName(actor, stage, index) {
    if(actor.company && actor.company.name) {
      return <div className='actorTab'><b>{actor.company.name}</b> {this.renderRemoveBtn(stage, index)}</div>;
    }
    if(actor.person && actor.person.name) {
      return <div className='actorTab'><b>{actor.person.name}</b> {this.renderRemoveBtn(stage, index)}</div>
    }
    return <div className='actorTab'><i>Pick an actor</i> {this.renderRemoveBtn(stage, index)}</div>
  }

  renderRemoveBtn(stage, index) {
    if(index === 0) {
      return null;
    }
    return <Button className='deleteActorBtn' onClick={() => this.removeActor(stage, index)}><FontAwesomeIcon icon={faTimesCircle}/></Button>
  }

  removeActor(stage, index) {
    if(stage === 'bidder') {
      let currentLenders = this.state.currentLenders;
      currentLenders.splice(index, 1)
      this.setState({
        currentLenders: currentLenders
      })
    }
    if(stage === 'target') {
      let currentTargets = this.state.currentTargets;
      currentTargets.splice(index, 1)
      this.setState({
        currentTargets: currentTargets
      })
    }
    if(stage === 'vendor') {
      let currentVendors = this.state.currentVendors;
      currentVendors.splice(index, 1)
      this.setState({
        currentVendors: currentVendors
      })
    }
  }

  isActorACompany(actor) {
    //We want to display the tab on by default
    if(actor.person && actor.person.uuid) {
      return false
    }
    return true
  }

  getCurrentlySelectedCompany(company) {

    if(company.entity_type === 'company') {
      return this.state.allCompaniesOptions.find(companyOption => companyOption.value === company.company.uuid)
    }

    if(company.entity_type === 'person') {
      return this.state.allPersonsOptions.find(personOption => personOption.value === company.person.uuid)
    }

    return null
  }

  getCurrentlySelectedParentCompanies(company) {
    if(!this.isActorACompany(company)) {
      return null
    }
    return company.parentCompanies.map((parent) => this.state.allCompaniesOptions.find((option) => option.value === parent.uuid))
  }
  renderCompany(company, stage, index) {
    let actorValue = this.getCurrentlySelectedCompany(company)
    let parents = this.getCurrentlySelectedParentCompanies(company)
    return     <Tab key={'company-tab-'+stage+index} eventKey={stage+index} title={this.getTabName(company, stage, index)}>
      <Row>
        <Col xs={12} sm={this.isActorACompany(company) ? 6 : 12}>
          <b>Actor</b>
          <CreatableSelect value={actorValue} isLoading={this.state.companiesLoading || this.state.personsLoading} onCreateOption={(companyName) => this.createCompany(companyName, stage, index)} onChange={(event) => this.handleChangeCompany(event, stage, index)} options={
            [{
              label: 'Companies',
              options: this.state.allCompaniesOptions,
            },
            {
              label: 'Persons',
              options: this.state.allPersonsOptions
            }]
           } />
        </Col>
        {this.isActorACompany(company) ? <Col xs={12} sm={6}>
            <b>Parent companies</b>
            <Select isMulti={true} value={parents} disabled={this.state.companiesLoading} onChange={(event) => this.handleChangeParentCompany(event, stage, index)} isClearable options={this.state.allCompaniesOptions} />
        </Col> : null}
      </Row>
    {this.isActorACompany(company) ? <div><Row><b>People</b></Row>
        <Row>
          <ListGroup>
            {company.persons.map((person, personIndex) => this.renderPersonRow(person, {'stage': stage, 'index': index, 'personIndex': personIndex}))}
            <ListGroup.Item  onClick={() => this.handleShowPersonModal({'stage': stage, 'index': index})} className="addbutton" action variant="dark"><Row className="justify-content-center"><Col><b>Add person</b></Col></Row></ListGroup.Item>
          </ListGroup>
        </Row>
        <hr></hr>
        <Row><b>Advisor(s)</b></Row>
        <ListGroup>
          {company.advisors.map((advisor, advisorIndex) => this.renderAdvisor(advisor, stage, advisorIndex, index))}
          <ListGroup.Item>
              <Row>
                <Col xs={6}>
                  <Select disabled={this.state.companiesLoading} onChange={this.handleChangeNewAdvisor} options={this.state.allCompaniesOptions} />
                </Col>
                <Col xs={4}>
                  <Select isMulti={true} onChange={this.handleChangeNewAdvisorType} placeholder='Advisor type' isClearable options={this.getAdvisorTypes()} />
                </Col>
                <Col xs={2}>
                    <Button onClick={() => this.addNewAdvisor(stage, index)} disabled={!this.state.selectedNewAdvisorCompany}>Add advisor</Button>
                </Col>
              </Row>
          </ListGroup.Item>
        </ListGroup></div> : null}
        <Row><b>Comment</b></Row>
        <Row><Form.Control value={company.comment || ''} onChange={(event) => this.handleChangeCompanyComment(event, stage, index)} type="text" placeholder="eg: 2% of deal" /></Row></Tab>
  }
  renderActors(actors, stage) {
    return <div key={stage+'-actors'}>
      <Button onClick={() => this.addActor(stage)}>Add</Button>
    <Tabs defaultActiveKey={stage+"0"} id={stage+"-tabs"}>
        {actors.map((actor, index) => this.renderCompany(actor, stage, index))}
    </Tabs>
    </div>
  }

  addActor(stage) {
    this.hasUnsavedData()
    if(stage === 'bidder') {
      let currentLenders = this.state.currentLenders;
      currentLenders.push(this.createActor())
      this.setState({
        currentLenders: currentLenders
      })
    }
    if(stage === 'target') {
      let currentTargets = this.state.currentTargets;
      currentTargets.push(this.createActor())
      this.setState({
        currentTargets: currentTargets
      })
    }
    if(stage === 'vendor') {
      let currentVendors = this.state.currentVendors;
      currentVendors.push(this.createActor())
      this.setState({
        currentVendors: currentVendors
      })
    }
  }

  hasUnsavedData() {
    window.onbeforeunload = () => true
  }

  dataSaved() {
    window.onbeforeunload = undefined
  }
  componentDidMount() {
    this.loadCompanies();
    this.loadPersons();
  }

  mapActorApiFormatToFrontend(actor) {
    return actor
  }

  componentDidUpdate(prevProps) {
    let newUuid = this.props.selected.deal?.uuid
    let prevUuid = prevProps.selected.deal?.uuid
    if (newUuid !== prevUuid) {
      let selectedDeal = Object.assign({}, this.props.selected.deal)
      if (this.props.selected.deal === undefined) {
        this.setState({
          deal: {
            linkWith: null,
            totalCapital: null,
            totalCapitalBracket: null,
            percentSold: null,
            currency: null,
            dealType: null,
            status: null,
            comment: null,
            nickname: null,
            postDealValuation: null,
            dateAnnounced: null,
            dateCompleted: null,
            dateRumoured: null
          },
          currentLenders: [
            {
              company: null,
              person: null,
              comment: null,
              persons: [],
              parentCompanies: [],
              advisors: []
            }
          ],
          currentTargets: [
            {
              company: null,
              comment: null,
              person: null,
              persons: [],
              parentCompanies: [],
              advisors: []
            }
          ],
          currentVendors: [
            {
              company: null,
              comment: null,
              person: null,
              persons: [],
              parentCompanies: [],
              advisors: []
            }
          ]
        })
        return;
      }
      let lenders = Object.assign([], this.props.selected.lenders)
      let targets = Object.assign([], this.props.selected.targets)
      let vendors = Object.assign([], this.props.selected.vendors)
      selectedDeal.linkWith = this.props.selected.deal.linkedWith
      
      selectedDeal.dateAnnounced = new Date(selectedDeal.dateAnnounced)
      selectedDeal.dateCompleted = new Date(selectedDeal.dateCompleted)
      selectedDeal.dateRumoured = new Date(selectedDeal.dateRumoured)

      //TODO handle all dropdown like this
      selectedDeal.dealType = this.getDealTypes().map((dType) => {return dType.options}).flat().find((type) => type.value === selectedDeal.dealType)
      selectedDeal.linkWith = this.getLinkedDeals().find(deal => deal.value === selectedDeal.linkedWith?.deal.uuid) || null
      selectedDeal.status = this.getDealStatuses().find((status) => status.value === selectedDeal.status) || null
      selectedDeal.stage = this.getDealStages().find((stage) => stage.value === selectedDeal.stage) || null
      selectedDeal.currency = this.getCurrencies().find((currency) => currency.value === selectedDeal.currency) || null

      this.setState({
        deal: selectedDeal,
        currentLenders: lenders.map(lender => this.mapActorApiFormatToFrontend(lender)),
        currentVendors: vendors.map(vendor => this.mapActorApiFormatToFrontend(vendor)),
        currentTargets: targets.map(target => this.mapActorApiFormatToFrontend(target))

      })
    }
  }
}

export default AddDeal;
