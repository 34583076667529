import React from 'react';
import { Modal, Button, Alert} from 'react-bootstrap';

class ConfirmationModal extends React.Component {
  render() {
    return <Modal show={this.props.open} onHide={this.props.handleCancel}>
    <Modal.Header closeButton>
      <Modal.Title>{this.props.title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        {this.props.error ? <Alert variant="danger">{this.props.error}</Alert> : null}
        {this.props.content}
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={this.props.handleCancel}>
        Cancel
      </Button>
      <Button variant="primary" onClick={this.props.handleConfirm}>
        {this.props.confirmText}
      </Button>
    </Modal.Footer>
  </Modal>;
  }
}

export default ConfirmationModal;
