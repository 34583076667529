import React from "react";
import { Alert } from "react-bootstrap";
class LaravelValidationErrors extends React.Component {
    render() {
        const {errors, noTitles} = this.props

        if(!errors || !Object.keys(errors).length) {
            return null
        }

        return <Alert variant="warning">
            <b>Please correct the following error and try again:</b>
            {noTitles ? this.getWithoutTitle(errors) : this.getWithTitle(errors)}
        </Alert>
    }

    getWithoutTitle(errors) {
        return Object.keys(errors).map((fieldKey) => <div><ul>{errors[fieldKey].map(fieldError => <li>{fieldError}</li>)}</ul></div>)
    }

    getWithTitle(errors) {
        return Object.keys(errors).map((fieldKey) => <div><b>{fieldKey}:</b><ul>{errors[fieldKey].map(fieldError => <li>{fieldError}</li>)}</ul></div>)
    }
}
export default LaravelValidationErrors;