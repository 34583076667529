import React from 'react';
import Loader from "react-loader-spinner"
import { toast } from 'react-toastify';
import autoBind from 'react-autobind';

class TaxonomyPage extends React.Component {
  constructor() {
    super();
    this.state = {
      taxonomies: [],
      loading: true
    };
    autoBind(this);
  }

  loadTaxonomyList() {
    fetch(process.env.REACT_APP_API_URL+"/taxonomy", {headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+localStorage.getItem("apiToken")
    }}).then(response => {
      if(!response.ok) {
        toast.error("Failed to load taxonomy");
        this.setState({loading: false})
      }
      
      return response.json()
    })
    .then(data => {
      this.setState({taxonomies: data, loading: false})
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  }

  renderTaxonomyList(taxonomies) {
    if(!taxonomies) {
        return null
    }

    return <ul>
        {taxonomies.map((taxonomy => <><li>{taxonomy.label}</li>{this.renderTaxonomyList(taxonomy.subs)}</>
        ))}
    </ul>
  }

  render() {
    return <div className='peoplePageMargins'>

        <div className='personListContainer'>
            {this.state.loading ? <Loader type="ThreeDots" color="#00BFFF" height={80} width={80} /> : null}
            {this.renderTaxonomyList(this.state.taxonomies)}
        </div>
    </div>;
  }

  componentDidMount() {
    this.loadTaxonomyList()
  }
}

export default TaxonomyPage;
