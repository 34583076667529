import React from 'react';
import {Form, Col, Row, Image, Button} from 'react-bootstrap'
import 'holderjs';
import './PeoplePage.css';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import Loader from 'react-loader-spinner';
import autoBind from 'react-autobind';

class AddPerson extends React.Component {
  constructor(props) {
    super();
    autoBind(this);
    this.state = {
      person: {
          name: props.selected.name || '',
          linkedin_url: props.selected.linkedin_url || '',
          location: props.selected.full_location || '',
          phone_number: props.selected.phone_number || '',
          profile_picture: props.selected.profile_picture || ''
      },
      selectedPerson: props.selected,
      loading: false,
      submitCallback: props.submitCallback
    };
  }

  resetFields() {
      this.setState({
          person: {
              name: '',
              linkedin_url: '',
              location: '',
              phone_number: '',
              profile_picture: ''
          }
      });
  }
  changeName(event) {
      this.setPersonState('name', event.target.value)
  }

  changeLiUrl(event) {
    this.setPersonState('linkedin_url', event.target.value)
  }

  changeLocation(event) {
    this.setPersonState('location', event)
  }

  changePhoneNumber(event) {
    this.setPersonState('phone_number', event.target.value)
  }

  changeProfilePicture(event) {
    this.setPersonState('profile_picture', event.target.value)
  }

  setPersonState(key, value) {
      let person = this.state.person
      person[key] = value
      this.setState({person: person})
  }

  submitForm() {
      this.setState(
          {
              loading: true
          }
      )
      this.state.submitCallback(this.state.person, this.props.selected.uuid).then((response) => {
            this.setState({loading: false})
            console.log(this.state.selectedPerson)
            if(response && !response.errors) {
                this.resetFields()
            }
      })
  }

  render() {
    return <div>
        <Form>
            <Row>
                <Col xs={12} md={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>Name*</Form.Label>
                        <Form.Control value={this.state.person.name} onChange={this.changeName} type="text" placeholder="eg: John Doe" />
                    </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>LinkedIn URL</Form.Label>
                        <Form.Control value={this.state.person.linkedin_url} onChange={this.changeLiUrl} type="text" placeholder="eg: https://www.linkedin.com/in/elon-musk-96a6a0201/" />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col xs={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>Location*</Form.Label>
                        <GooglePlacesAutocomplete apiKey="AIzaSyCmKqTmIIMG_z4w8XJs306g4kdEfS2WCSA" selectProps={{value: this.state.person.location, onChange: this.changeLocation}} />
                    </Form.Group>
                </Col>
                <Col xs={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control value={this.state.person.phone_number} onChange={this.changePhoneNumber} type="text" placeholder="eg: +44745687979" />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col xs={6} md={2}>
                    <Image className='profilePic' src={this.state.person.profile_picture || "holder.js/100x100"} rounded />
                </Col>
                <Col xs={6} md={10}>
                    <Form.Group className="mb-3">
                        <Form.Label>Profile Picture</Form.Label>
                        <Form.Control value={this.state.person.profile_picture} onChange={this.changeProfilePicture} type="text" placeholder="eg: https://upload.wikimedia.org/wikipedia/commons/8/85/Elon_Musk_Royal_Society_%28crop1%29.jpg" />
                    </Form.Group>
                </Col>
            </Row>
            <hr></hr>
            <Row><Col><Button disabled={this.state.loading} variant='dark' onClick={this.submitForm}>{this.state.loading ? <Loader type="ThreeDots" height={'100%'} color="#FFFFFF" /> : this.props.selected.uuid ? 'Update' : 'Create'}</Button></Col></Row>
        </Form>
    </div>;
  }
  

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.selected.uuid !== prevProps.selected.uuid) {
      this.setState({
          person: {
            name: this.props.selected.name || '',
            linkedin_url: this.props.selected.linkedin_url || '',
            location: this.props.selected.full_location || '',
            phone_number: this.props.selected.phone_number || '',
            profile_picture: this.props.selected.profile_picture || ''
        }
      })
    }
  }
}

export default AddPerson;
