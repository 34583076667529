import React from 'react';
import { toast } from 'react-toastify';
import autoBind from 'react-autobind';
import Loader from "react-loader-spinner";
import {Col, Row, Card, Table} from 'react-bootstrap';
import { Doughnut } from "react-chartjs-2";

class StatsPage extends React.Component {
  constructor() {
    super();
    this.state = {
      dealStats: [],
      formattedData: {},
      peopleStats: [],
      dataToShow: [
        {
          'identifier': 'type',
          'label': 'Types'
        },
        {
          'identifier': 'status',
          'label': 'Deal statuses'
        },
        {
          'identifier': 'stage',
          'label': 'Deal stage'
        },
        {
          'identifier': 'amount',
          'label': 'Amounts'
        }
      ],
      dealStatsLoading: true,
      peopleLoading: true
    };
    autoBind(this);
  }

  componentDidMount() {
    this.loadDealStats()
    this.loadPeopleStats()
    //ChartJS.register(ArcElement, Tooltip, Legend)
  }

  loadPeopleStats() {
    fetch(process.env.REACT_APP_API_URL+"/stats/persons", {headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+localStorage.getItem("apiToken")
    }}).then(response => {
      if(!response.ok) {
        toast.error("Failed to load companies")
        this.setState({loading: false})
      }
      return response.json()
    })
    .then(data => {
      this.setState({peopleStats: data, peopleLoading: false})
    })
    .catch((error) => {
      toast.error(error)
    });
  }

  loadDealStats() {
    fetch(process.env.REACT_APP_API_URL+"/stats/deals", {headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+localStorage.getItem("apiToken")
    }}).then(response => {
      if(!response.ok) {
        toast.error("Failed to load companies")
        this.setState({loading: false})
      }
      return response.json()
    })
    .then(data => {
      this.setState({dealStats: data, formattedData: data.formatted_for_charts, dealStatsLoading: false})
    })
    .catch((error) => {
      toast.error(error)
    });
  }

  applyFilter() {
    
  }


  renderPieCharts(currentData) {
    const formattedData = this.state.formattedData[currentData.identifier] || {}

    return <Col xs={12} sm={6}><Card>
        <Card.Body>
          <Card.Title>{currentData.label}</Card.Title>
          {this.state.dealStatsLoading ? <Loader type="ThreeDots" color="#00BFFF" height={80} width={80} /> : null}
          <Doughnut data={formattedData}/>
        </Card.Body>
    </Card></Col>
  }

  renderOnePerson(person) {
    return <tr>
        <td>1</td>
        <td>{person.person.name}</td>
        <td>{person.stats.deal_count}</td>
        <td>{person.stats.score}</td>
    </tr>
  }

  renderAllPersons() {
    if (this.state.peopleLoading) {
      return <Loader type="ThreeDots" color="#00BFFF" height={80} width={80} />
    }

    return this.state.peopleStats.map(peopleStat => this.renderOnePerson(peopleStat))
  }

  render() {
    return <div className='peoplePageMargins'>
          <Row>
            {this.state.dataToShow.map(data => this.renderPieCharts(data))}
          </Row>
          <hr/>
          <Table>
            <thead>
              <th>Rank</th>
              <th>Name</th>
              <th>Number of deals</th>
              <th>Score</th>
            </thead>
            <tbody>
                {this.renderAllPersons()}
            </tbody>
          </Table>
    </div>;
  }
}

export default StatsPage;
