import React from 'react';
import { Modal, Button } from 'react-bootstrap'
import './CompanyProfile.css'
import AddCompany from './AddCompany';
import { toast } from 'react-toastify';
import autoBind from 'react-autobind';
import "./QuickAddActor.css";

class QuickAddActor extends React.Component {
  constructor(props) {
    super();
    this.state = {
        name: props.name,
        openModal: props.open,
    };
    autoBind(this)
  }

  submitCallback(company) {
      return fetch(process.env.REACT_APP_API_URL+"/company", {
        method: 'POST',
        headers: {
          'Content-Type':'application/json','Authorization': 'Bearer '+localStorage.getItem("apiToken")
        },
        body: JSON.stringify(company),
      }).then(response => response.json())
      .then(data => {
        if(data.errors) {
          this.setState({errors: data.errors})
        } else {
          this.props.submitCallback(data)
        }

        return data
      })
      .catch((error) => {
        toast.error("Failed to add company")
        console.error('Error:', error);
      });
  }

  render() {
    return <Modal show={this.props.open} onHide={this.props.handleClose}>
        <div className='inner-padding'>
          <AddCompany selected={{name: this.props.name}} submitCallback={this.submitCallback}/>
          <hr/>
          <Button variant="danger" onClick={this.props.handleClose}>Cancel</Button>
        </div>
    </Modal>
  }

}

export default QuickAddActor;