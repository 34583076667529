import React from 'react';
import AddPerson from './AddPerson';
import PersonList from './PersonList';
import './PeoplePage.css';
import Loader from "react-loader-spinner";
import {ListGroup, Badge} from "react-bootstrap"
import { toast } from 'react-toastify';
import LaravelValidationErrors from './LaravelValidationErrors';
import ConfirmationModal from './ConfirmationModal';
import autoBind from 'react-autobind';

class PeoplePage extends React.Component {
  constructor() {
    super();
    this.state = {
      persons: [],
      loading: true,
      errors: {},
      selectedPerson: {},
      selectedIndex: null,
      deleteModalOpen: false,
      deleteModalErrors: null,
      deleteModalContent: null
    };
    autoBind(this);
  }

  addPerson(person, uuid) {
    this.setState({errors: {}})
    if(uuid) {
      return fetch(process.env.REACT_APP_API_URL+"/person/"+uuid, {
        method: 'PUT',
        headers: {
          'Content-Type':'application/json','Authorization': 'Bearer '+localStorage.getItem("apiToken")
        },
        body: JSON.stringify(person),
      }).then(response => response.json())
      .then(data => {
        if(data.errors) {
          console.log(data)
          this.setState({errors: data.errors})
        } else {
          this.setState({selectedPerson: {}, selectedIndex: null})
          toast.success('Updated '+person.name)
        }
        // TODO update in the state
        return data
      })
      .catch((error) => {
        toast.error('Failed to create')
        console.error('Error:', error);
      });
    } else {
      return fetch(process.env.REACT_APP_API_URL+"/person", {
        method: 'POST',
        headers: {
          'Content-Type':'application/json','Authorization': 'Bearer '+localStorage.getItem("apiToken")
        },
        body: JSON.stringify(person),
      }).then(response => response.json())
      .then(data => {
        if(data.errors) {
          this.setState({errors: data.errors})
        } else {
          let persons = this.state.persons
          persons.unshift(data)
          toast.success('Added '+data.name)
          this.setState({persons: persons})
        }
        return data
      })
      .catch((error) => {
        toast.error('Failed to update')
        console.error('Error:', error);
      });
  
    }

  }

  loadPersonList() {
    fetch(process.env.REACT_APP_API_URL+"/person", {headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+localStorage.getItem("apiToken")
    }}).then(response => {
      if(!response.ok) {
        toast.error("Failed to load persons");
        this.setState({loading: false})
      }
      
      return response.json()
    })
    .then(data => {
      this.setState({persons: data, loading: false})
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  }

  selectPerson(index) {
    this.setState({
      selectedPerson: this.state.persons[index],
      selectedIndex: index
    })
  }

  deletePerson(index) {
    let selectedPerson = this.state.persons[index]
    this.setState({
      selectedPerson: selectedPerson,
      selectedIndex: index,
      deleteModalOpen: true,
      deleteModalContent: selectedPerson.name+' is about to be deleted, Are you sure ?'
    })
  }

  renderDeleteError(errors) {
    return <div>
        Failed to delete, person is present in the following resources, delete them first and try again:
        <ListGroup>
          {errors.map(error => <ListGroup.Item key={error.uuid+error.type} action target="_blank" href={"/deals/"+error.uuid}>{error.name} <Badge className='pullRight' bg="dark">{error.type}</Badge></ListGroup.Item>)}
        </ListGroup>
    </div>
  }
  confirmDeletePerson() {
    return fetch(process.env.REACT_APP_API_URL+"/person/"+this.state.selectedPerson.uuid, {
      method: 'DELETE',
      headers: {
        'Content-Type':'application/json','Authorization': 'Bearer '+localStorage.getItem("apiToken")
      },
    }).then(response => response.json())
    .then(data => {
      if(data.errors) {
        this.setState({
          deleteModalErrors: this.renderDeleteError(data.errors)
        })
      } else {
        toast.success('Deleted '+this.state.selectedPerson.name)
        let persons = this.state.persons
        persons.splice(this.state.selectedIndex, 1)
        this.setState({
          selectedPerson: {},
          selectedIndex: null,
          persons: persons,
          deleteModalErrors: null,
          deleteModalOpen: null,
          deleteModalContent: null
        }) 
        
      }
      return data
    })
    .catch((error) => {
      toast.error('Failed to delete')
      console.error('Error:', error);
    });
  }


  render() {
    return <div className='peoplePageMargins'>
          <ConfirmationModal 
            open={this.state.deleteModalOpen}
            handleCancel={() => this.setState({deleteModalErrors: null, deleteModalOpen: false, deleteModalContent: null})}
            title={'Person deletion'}
            content={this.state.deleteModalContent}
            error={this.state.deleteModalErrors}
            confirmText={'Delete'}
            handleConfirm={this.confirmDeletePerson}
          />
        <div className='addPersonContainer'>
            <LaravelValidationErrors errors={this.state.errors} />
            <AddPerson submitCallback={this.addPerson} selected={this.state.selectedPerson}/>
        </div>
        <div className='personListContainer'>
            {this.state.loading ? <Loader type="ThreeDots" color="#00BFFF" height={80} width={80} /> : null}
            <PersonList persons={this.state.persons} selected={this.state.selectedPerson} selectCallback={this.selectPerson} deleteCallback={this.deletePerson}/>
        </div>
    </div>;
  }

  componentDidMount() {
    this.loadPersonList()
  }
}

export default PeoplePage;
