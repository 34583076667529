import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { ToastContainer } from 'react-toastify';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import CompaniesPage from './CompaniesPage';
import DealProfile from './DealProfile';
import DealsPage from './DealsPage';
import PeoplePage from './PeoplePage'
import DealcoreNavbar from './DealcoreNavbar';
import CompanyProfile from './CompanyProfile';
import PersonProfile from './PersonProfile';
import SearchPage from './SearchPage';
import TaxonomyPage from './TaxonomyPage';
import Login from './Login';
import StatsPage from './StatsPage';
function App() {

  let currentToken = localStorage.getItem("apiToken")
  let currentUser = localStorage.getItem("currentUser")

  if(!currentToken || !currentUser) {
    return <Login/>
  }

  return (
    <div>
      <DealcoreNavbar />
      <ToastContainer/>
      <Router>
      <Switch>
          <Route path="/companies/:companyUuid" render={(props => <CompanyProfile companyUuid={props.match.params.companyUuid} />)}/>
          <Route path="/companies">
            <CompaniesPage />
          </Route>

          <Route path="/people/:personUuid" render={(props => <PersonProfile personUuid={props.match.params.personUuid} />)} />
          <Route path="/people">
            <PeoplePage />
          </Route>
          <Route path="/deals/:dealUuid" render={(props => <DealProfile dealUuid={props.match.params.dealUuid} />)}/>
          <Route path="/deals">
            <DealsPage />
          </Route>
          <Route path="/search">
            <SearchPage />
          </Route>
          <Route path="/taxonomy">
            <TaxonomyPage />
          </Route>
          <Route path="/stats">
            <StatsPage/>
          </Route>
          <Route path="/">
            <DealsPage />
          </Route>
        </Switch>
    </Router>
    </div>

  );
}

export default App;
