import React from 'react';
import {Col, Row, Button, Table} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenSquare, faExpandAlt, faTrashAlt, faBuilding, faUser } from '@fortawesome/free-solid-svg-icons'

class DealList extends React.Component {
  constructor(props) {
    super();
    this.state = {
      deals: props.deals
    };
  }

  formatActor(actor, key) {
    if(actor.company) {
      return <div key={key+'-actor'}><FontAwesomeIcon icon={faBuilding} /> {actor.company.name}</div>
    }
    return <div key={key+'-actor'}><FontAwesomeIcon icon={faUser} /> {actor.person.name}</div>
  }

  classNameActive(index) {
    if(this.props.selectedIndex != null && this.props.selectedIndex === index) {
      return 'active-bg'
    }
    return null
  }
  render() {
    return <Table striped bordered hover size="sm">
    <thead>
        <tr>
        <th>Name</th>
        <th>Capital Provider</th>
        <th>Targets</th>
        <th>Vendors</th>
        <th>Deal info</th>
        <th>Dates</th>
        <th></th>
        </tr>
    </thead>
    <tbody>
        {this.props.deals.map((deal, index) =><tr className={this.classNameActive(index)} key={deal.deal.uuid+"_tr"}>
                <td>{deal.deal.nickname}</td>
                <td>{deal.lenders.map((lender, actor_index) => this.formatActor(lender, index+'-lender-'+actor_index))}</td>
                <td>{deal.targets.map((target, actor_index) => this.formatActor(target, index+'-target-'+actor_index))}</td>
                <td>{deal.vendors.map((vendor, actor_index) => this.formatActor(vendor, index+'-vendor-'+actor_index))}</td>
                <td>
                  <ul>
                      {deal.deal.totalCapital && <li><b>Total capital:</b> {deal.deal.totalCapital} {deal.deal.currency}</li>}
                      {deal.deal.percentSold && <li><b>% sold:</b> {deal.deal.percentSold}</li>}
                      {deal.deal.stage && <li><b>Deal stage: </b>{deal.deal.stage}</li>}
                      <li><b>Deal type:</b> {deal.deal.dealType}</li>

                  </ul>
                </td>
                <td>
                    <ul>
                      {deal.deal.dateAnnounced && <li><b>Announced:</b> {deal.deal.dateAnnounced}</li>}
                      {deal.deal.dateCompleted && <li><b>Completed:</b> {deal.deal.dateCompleted}</li>}
                      {deal.deal.dateRumoured && <li><b>Rumoured:</b> {deal.deal.dateRumoured}</li>}
                    </ul>
                </td>
                <td>
                    <Row><Col xs={12}><Button onClick={() => this.props.selectCallback(index)} variant="dark"><FontAwesomeIcon icon={faPenSquare} /></Button></Col></Row>
                    <Row><Col xs={12}><Button variant="light" target="_blank" href={'/deals/'+deal.deal.uuid}><FontAwesomeIcon icon={faExpandAlt} /></Button></Col></Row>
                    <Row><Col xs={12}><Button onClick={() => this.props.deleteCallback(index)} variant="danger"><FontAwesomeIcon icon={faTrashAlt} /></Button></Col></Row>
                </td>
                </tr>)}
    </tbody>
</Table>
  }

  componentDidMount() {
  }
}

export default DealList;
