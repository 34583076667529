import React from 'react';
import { toast } from 'react-toastify';
import autoBind from 'react-autobind';
import AsyncSelect from 'react-select/async';
import { Badge } from 'react-bootstrap';
class SearchPage extends React.Component {
  constructor() {
    super();
    this.state = {
      results: [],
      loading: false,
    };
    autoBind(this);
  }

  componentDidMount() {
  }

  loadOptions(input) {
    let options = this.state.results.map((result) => {
        return {
            label: <div><b>Company</b> : {result.name}</div>,
            value: result.uuid,
            type: result.type
        }
    })
    return options
  }

  search(newValue) {
    return fetch(process.env.REACT_APP_API_URL+"/search", {
      method: 'POST',
      headers: {
        'Content-Type':'application/json','Authorization': 'Bearer '+localStorage.getItem("apiToken")
      },
      body: JSON.stringify({
        query: newValue
      })
    }).then(response => {
      if(!response.ok) {
        toast.error("Failed to search")
        this.setState({loading: false})
      }
      return response.json()
    })
    .then(data => {
      this.setState({results: data, loading: false})
      return data.map((result) => {
        return {
            label: <div><Badge bg="secondary">{result.type}</Badge> {result.name}</div>,
            value: result.uuid,
            type: result.type
        }
    })
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  }

  openResourcePage(selected) {
    const mapping = {
        'company': 'companies',
        'person': 'people',
        'deal': 'deals'
    }
    window.open('/'+mapping[selected.type]+'/'+selected.value, '_blank', 'noopener,noreferrer');
  }

  render() {
    return <div className='peoplePageMargins'>
    <AsyncSelect loadOptions={this.search} onChange={this.openResourcePage} />
    </div>;
  }
}

export default SearchPage;
