import React from 'react';
import {Container, Button} from 'react-bootstrap'
import Form from 'react-bootstrap/Form';
import { toast, ToastContainer } from 'react-toastify';
class Login extends React.Component {
    constructor(props) {
        super();
        this.state = {
            username: null,
            password: null
        }
        this.login = this.login.bind(this)
    }
    login() {
        return fetch(process.env.REACT_APP_API_URL+"/login", {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              email: this.state.username,
              password: this.state.password
            }),
          }).then(response => response.json())
          .then(data => {
            if(data.token) {
              console.log(data.token)
                localStorage.setItem("apiToken", data.token)
                localStorage.setItem("currentUser", this.state.username)
                window.location.reload()
            } else {
                toast.error("Incorrect username and/or password")
            }
          })
          .catch((error) => {
            toast.error("Unknown error, try again")
          });
    }

    render() {
        return <Container>
                <h2>Login</h2>
                <Form>
                <ToastContainer/>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control value={this.state.username} type="email" onChange={(event) => {this.setState({username: event.target.value})}}placeholder="Enter email" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control value={this.state.password} type="password" onChange={(event) => {this.setState({password: event.target.value})}} placeholder="Password" />
                </Form.Group>
                <Button variant="primary" onClick={this.login}>
                Login
                </Button>
            </Form>
        </Container>
    }
}

export default Login;
