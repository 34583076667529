import React from 'react';
import {ListGroup, Image, Col, Row, Button} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenSquare, faExpandAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
class PersonList extends React.Component {
  render() {
    return <div>
      <ListGroup>
        {this.props.persons.map((person, index) =>this.renderPerson(person, index))}
      </ListGroup>
      
    </div>
  }
  

  renderPerson(person, index) {
    return <ListGroup.Item key={person.uuid} active={this.isSelected(person)}>
        <Row>
          <Col xs={1}><Image src={person.profile_picture} thumbnail /></Col>
          <Col xs={9}>
            <Row><b>{person.name}</b></Row>
            <Row><i>{person.location}</i></Row>
            <Row><a href={person.linkedin_url}>{person.linkedin_url}</a></Row>
          </Col>
          <Col xs={2}>
                <Button variant='dark' onClick={() => this.props.selectCallback(index)}><FontAwesomeIcon icon={faPenSquare} /></Button>
                {' '}
                <Button target="_blank" href={'/people/'+person.uuid} variant="light"><FontAwesomeIcon icon={faExpandAlt} /></Button>
                {' '}
                <Button variant='danger' onClick={() => this.props.deleteCallback(index)}><FontAwesomeIcon icon={faTrashAlt} /></Button>
              </Col>
        </Row>
      </ListGroup.Item>
  }

  isSelected(person) {
    if(!this.props.selected) {
      return false;
    }

    if(this.props.selected.uuid === person.uuid) {
      return true;
    }
    return false;
  }
  componentDidMount() {
  }
}

export default PersonList;
