import React from 'react';
import {ListGroup, Col, Row, Button} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenSquare, faExpandAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
class CompanyList extends React.Component {
  constructor(props) {
    super();
    this.state = {

    };
  }

  render() {
    return <div>
      <ListGroup>
        {this.props.companies.map((company, index) =>this.renderCompany(company, index))}
      </ListGroup>
      
    </div>
  }

  renderCompany(company, index) {
    return <ListGroup.Item key={company.uuid} active={this.isSelected(company)}>
        <Row>
          <Col xs={2}>{company.name}</Col>
          <Col xs={8}>
            <Row><i>{company.hq_location ? company.hq_location.label : null}</i></Row>
            <Row><a href={company.website}>{company.website}</a></Row>
          </Col>
          <Col xs={2}>
            <Button variant='dark' onClick={() => this.props.selectCallback(index)}><FontAwesomeIcon icon={faPenSquare} /></Button>
            {' '}
            <Button target="_blank" href={"/companies/"+company.uuid} variant="light"><FontAwesomeIcon icon={faExpandAlt} /></Button>
            {' '}
            <Button variant='danger' onClick={() => this.props.deleteCallback(index)}><FontAwesomeIcon icon={faTrashAlt} /></Button>
          </Col>
        </Row>
      </ListGroup.Item>
  }

  isSelected(Company) {
    if(!this.props.selected) {
      return false;
    }

    if(this.props.selected.uuid === Company.uuid) {
      return true;
    }
    return false;
  }
  componentDidMount() {
  }
}

export default CompanyList;
