import React from 'react';
import AddCompany from "./AddCompany";
import CompanyList from "./CompanyList";
import { toast } from 'react-toastify';
import Loader from "react-loader-spinner";
import LaravelValidationErrors from './LaravelValidationErrors';
import ConfirmationModal from './ConfirmationModal';
import { Badge, ListGroup } from 'react-bootstrap';
import autoBind from 'react-autobind';

class CompaniesPage extends React.Component {
  constructor() {
    super();
    this.state = {
      companies: [],
      selectedCompany: {},
      errors: {},
      loading: true,
      selectedIndex: null,
      deleteModalOpen: false,
      deleteModalErrors: null,
      deleteModalContent: null
    };
    autoBind(this);
  }

  addCompany(company, uuid) {
    this.setState({errors: {}})
    if(uuid) {
      return fetch(process.env.REACT_APP_API_URL+"/company/"+uuid, {
        method: 'PUT',
        headers: {
          'Content-Type':'application/json','Authorization': 'Bearer '+localStorage.getItem("apiToken")
        },
        body: JSON.stringify(company),
      }).then(response => response.json())
      .then(data => {
        // TODO update in the state
        if(data.errors) {
          this.setState({errors: data.errors})
        } else {
          this.setState({
            selectedCompany: {},
            selectedIndex: null
          })
          toast.success("Updated "+company.name)
        }
        return data
      })
      .catch((error) => {
        toast.error("Failed to update company")
        console.error('Error:', error);
      });
    } else {
      return fetch(process.env.REACT_APP_API_URL+"/company", {
        method: 'POST',
        headers: {
          'Content-Type':'application/json','Authorization': 'Bearer '+localStorage.getItem("apiToken")
        },
        body: JSON.stringify(company),
      }).then(response => response.json())
      .then(data => {
        if(data.errors) {
          this.setState({errors: data.errors})
        } else {
          let companies = this.state.companies
          companies.unshift(data)
          this.setState({companies: companies})
          toast.success("Added "+company.name)
        }

        return data
      })
      .catch((error) => {
        toast.error("Failed to add company")
        console.error('Error:', error);
      });
  
    }

  }

  loadCompanyList() {
    fetch(process.env.REACT_APP_API_URL+"/company", {headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+localStorage.getItem("apiToken")
    }}).then(response => {
      if(!response.ok) {
        toast.error("Failed to load companies")
        this.setState({loading: false})
      }
      return response.json()
    })
    .then(data => {
      this.setState({companies: data, loading: false})
    })
    .catch((error) => {
      toast.error(error)
    });
  }

  selectCompany(index) {
    this.setState({
      selectedCompany: this.state.companies[index],
      selectedIndex: index
    })
  }
  componentDidMount() {
    this.loadCompanyList()
  }
  renderDeleteError(errors) {
    return <div>
        Failed to delete, company is tied with the following resources:
        <ListGroup>
          {errors.map(error => <ListGroup.Item key={error.uuid+error.type} action target="_blank" href={error.type === 'deal' ? '/deals/'+error.uuid : '/people/'+error.uuid}>{error.name} <Badge className='pullRight' bg="dark">{error.type}</Badge></ListGroup.Item>)}
        </ListGroup>
    </div>
  }
  confirmDeleteCompany() {
    return fetch(process.env.REACT_APP_API_URL+"/company/"+this.state.selectedCompany.uuid, {
      method: 'DELETE',
      headers: {
        'Content-Type':'application/json','Authorization': 'Bearer '+localStorage.getItem("apiToken")
      },
    }).then(response => response.json())
    .then(data => {
      if(data.errors) {
        this.setState({
          deleteModalErrors: this.renderDeleteError(data.errors)
        })
      } else {
        toast.success('Deleted '+this.state.selectedCompany.name)
        let companies = this.state.companies
        companies.splice(this.state.selectedIndex, 1)
        this.setState({
          selectedPerson: {},
          selectedIndex: null,
          companies: companies,
          deleteModalErrors: null,
          deleteModalOpen: null,
          deleteModalContent: null
        }) 
        
      }
      return data
    })
    .catch((error) => {
      toast.error('Failed to delete')
      console.error('Error:', error);
    });
  }

  deleteCompany(index) {
    let selectedCompany = this.state.companies[index]
    this.setState({
      selectedCompany: selectedCompany,
      selectedIndex: index,
      deleteModalOpen: true,
      deleteModalContent: selectedCompany.name+' is about to be deleted, Are you sure ?'
    })
  }


  render() {
    return <div className='peoplePageMargins'>
        <ConfirmationModal 
            open={this.state.deleteModalOpen}
            handleCancel={() => this.setState({deleteModalErrors: null, deleteModalOpen: false, deleteModalContent: null})}
            title={'Company deletion'}
            content={this.state.deleteModalContent}
            error={this.state.deleteModalErrors}
            confirmText={'Delete'}
            handleConfirm={this.confirmDeleteCompany}
          />
        <div className='addCompanyContainer'>
            <LaravelValidationErrors errors={this.state.errors} />
            <AddCompany submitCallback={this.addCompany} selected={this.state.selectedCompany}/>
        </div>
        <hr/>
        <div className='personListContainer'>
            {this.state.loading ? <Loader type="ThreeDots" color="#00BFFF" height={80} width={80} /> : null}
            <CompanyList companies={this.state.companies} selected={this.state.selectedCompany} selectCallback={this.selectCompany} deleteCallback={this.deleteCompany}/>
        </div>
    </div>;
  }

}

export default CompaniesPage;
