import React from 'react';
import {Navbar, Nav, Container} from 'react-bootstrap';
import NavDropdown from 'react-bootstrap/NavDropdown';

class DealcoreNavbar extends React.Component {
  constructor() {
    super();
    this.state = {
    };
  }

  logout() {
    console.log("LOGOUT")
    localStorage.removeItem("currentUser")
    localStorage.removeItem("apiToken")
     window.location.reload()
  }

  render() {
    return <Navbar bg="light" expand="lg">
    <Container>
      <Navbar.Brand href="/">Dealcore</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
          <Nav.Link href="/">Home</Nav.Link>
          <Nav.Link href="/people">People</Nav.Link>
          <Nav.Link href="/companies">Companies</Nav.Link>
          <Nav.Link href="/deals">Deals</Nav.Link>
          <Nav.Link href="/search">Search</Nav.Link>
          <Nav.Link href="/taxonomy">Taxonomy</Nav.Link>
          <Nav.Link href="/stats">Statistics</Nav.Link>
          <NavDropdown title={localStorage.getItem("currentUser")} id="login-nav-dropdown">
            <NavDropdown.Item onClick={this.logout}>Logout</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>;
  }

  componentDidMount() {
  }
}

export default DealcoreNavbar;
